// dashboard/src/contexts/LocationContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const LocationContext = createContext(null);

// Cache key for localStorage
const CACHE_KEY = 'location_cache';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Helper to get cached data
const getCachedData = () => {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
  } catch (error) {
    console.error('Error reading from cache:', error);
  }
  return null;
};

// Helper to set cached data
const setCachedData = (data) => {
  try {
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error writing to cache:', error);
  }
};

export function LocationProvider({ children }) {
  const [locationsData, setLocationsData] = useState(() => {
    const cached = getCachedData();
    return cached ? {
      byId: cached.byId,
      allLocations: cached.allLocations,
    } : {
      byId: {},
      allLocations: []
    };
  });
  const [loading, setLoading] = useState(!locationsData.allLocations.length);
  const [error, setError] = useState(null);

  const fetchLocations = useCallback(async (force = false) => {
    // Check cache first
    if (!force) {
      const cached = getCachedData();
      if (cached) {
        setLocationsData(cached);
        setLoading(false);
        return;
      }
    }

    setLoading(true);
    try {
      const functions = getFunctions();
      const listLocations = httpsCallable(functions, 'listLocations');
      const response = await listLocations({});

      // Store both the array and the lookup object
      const locationMap = {};
      response.data.forEach(location => {
        locationMap[location.id] = location;
      });

      const newData = {
        byId: locationMap,
        allLocations: response.data
      };

      setLocationsData(newData);
      setCachedData(newData);
      setError(null);
    } catch (err) {
      console.error('Error fetching locations:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!locationsData.allLocations.length) {
      fetchLocations();
    }
  }, [fetchLocations, locationsData.allLocations.length]);

  const value = {
    locations: locationsData.allLocations,
    loading,
    error,
    refetchLocations: () => fetchLocations(true),
    getLocationName: (id) => locationsData.byId[id]?.description || `Unknown [${id}]`,
    getLocation: (id) => locationsData.byId[id]
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
}

export function useLocations() {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocations must be used within a LocationProvider');
  }
  return context;
}