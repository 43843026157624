// dashboard/src/pages/Locations.jsx
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import Spinner from '../components/common/Spinner'
import FilterButton from '../components/common/FilterButton'
import LocationActionMenu from '../components/menus/LocationsActionMenu'
import { useLocations } from '../contexts/LocationContext';
import { Table, TableHeader, TableHeaderCell, SortableTableHeaderCell, TableBody, TableRow, TableCell } from '../components/ui/Table';

const LocationsPage = () => {
  const navigate = useNavigate();
  const { locations, locationsLoading } = useLocations();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [sortField, setSortField] = useState('description');
  const [sortDirection, setSortDirection] = useState('asc');

  const filterCounts = useMemo(() => {
    return {
      all: locations.length,
      needsConfig: locations.filter(
        loc => !loc.secretKey || !loc.readers || loc.readers.length === 0
      ).length
    };
  }, [locations]);

  const filteredLocations = useMemo(() => {
    return locations
      .filter(location => {
        if (searchTerm && !location.description.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }

        // Status filters
        switch (activeFilter) {
          case 'needsConfig':
            return !location.secretKey || !location.readers || location.readers.length === 0;
          default:
            return true;
        }
      })
      .sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];
        const direction = sortDirection === 'asc' ? 1 : -1;
        return aValue < bValue ? -direction : direction;
      });
  }, [locations, searchTerm, activeFilter, sortField, sortDirection]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const SortHeader = ({ field, children }) => (
    <SortableTableHeaderCell onClick={() => handleSort(field)}
      isSorted={sortField === field} sortDirection={sortDirection}>
      {children}
    </SortableTableHeaderCell>
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Search Bar */}
      <div className="relative mb-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search locations by description..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Title */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Locations</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => navigate('/dashboard/locations/new')}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Create Location
          </button>
        </div>
      </div>

      {/* Filter Buttons */}
      <div className="mb-6">
        <FilterButton
          label="All"
          count={filterCounts.all}
          active={activeFilter === 'all'}
          onClick={() => setActiveFilter('all')}
        />
        <FilterButton
          label="Needs Configuration"
          count={filterCounts.needsConfig}
          active={activeFilter === 'needsConfig'}
          onClick={() => setActiveFilter('needsConfig')}
        />
      </div>

      {/* Locations Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {locationsLoading ? (
              <div className="flex justify-center items-center min-h-[400px]">
                <Spinner className="h-8 w-8 text-indigo-600" />
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <SortHeader field="id">ID</SortHeader>
                    <SortHeader field="description">Description</SortHeader>
                    <SortHeader field="products"># Products</SortHeader>
                    <SortHeader field="currency">Currency</SortHeader>
                    <TableHeaderCell><span className="sr-only">Actions</span></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredLocations.map((location) => (
                    <TableRow key={location.id}>
                      <TableCell>{location.id}</TableCell>
                      <TableCell>{location.description}</TableCell>
                      <TableCell>{location.products && location.products.length}</TableCell>
                      <TableCell>{location.currency}</TableCell>
                      <TableCell><LocationActionMenu location={location} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>

      {/* Show total count */}
      <div className="mt-6">
        <div className="text-sm text-gray-700">
          {filteredLocations.length !== locations.length
            ? `Showing ${filteredLocations.length} locations of ${locations.length}`
            : `${locations.length} locations`}
        </div>
      </div>
    </div>
  );
};

export default LocationsPage;