// dashboard/src/contexts/UserContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const UserContext = createContext(null);

const CACHE_KEY = 'user_cache';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const getCachedData = () => {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
  } catch (error) {
    console.error('Error reading from cache:', error);
  }
  return null;
};

const setCachedData = (data) => {
  try {
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error writing to cache:', error);
  }
};

export function UserProvider({ children }) {
  const [usersData, setUsersData] = useState(() => {
    const cached = getCachedData();
    return cached ? {
      byId: cached.byId,
      allUsers: cached.allUsers,
    } : {
      byId: {},
      allUsers: []
    };
  });
  const [usersLoading, setUsersLoading] = useState(!usersData.allUsers.length);
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(async (force = false) => {
    // Check cache first
    if (!force) {
      const cached = getCachedData();
      if (cached) {
        setUsersData(cached);
        setUsersLoading(false);
        return;
      }
    }

    setUsersLoading(true);
    try {
      const functions = getFunctions();
      const listUsers = httpsCallable(functions, 'listUsers');
      const response = await listUsers({});

      const userMap = {};
      response.data.forEach(user => {
        userMap[user.id] = user;
      });

      const newData = {
        byId: userMap,
        allUsers: response.data
      };

      setUsersData(newData);
      setCachedData(newData);
      setError(null);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(err);
    } finally {
      setUsersLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!usersData.allUsers.length) {
      fetchUsers();
    }
  }, [fetchUsers, usersData.allUsers.length]);

  // Add user management functions
  const activateUser = useCallback(async (userId) => {
    try {
      setUsersLoading(true);
      const functions = getFunctions();
      const activateUserFunction = httpsCallable(functions, 'activateUser');
      await activateUserFunction({ userId });

      // Update local state
      setUsersData(prev => {
        const updatedUsers = prev.allUsers.map(user =>
          user.id === userId ? { ...user, isActive: true } : user
        );
        const updatedUserMap = { ...prev.byId };
        updatedUserMap[userId] = { ...updatedUserMap[userId], isActive: true };

        return {
          byId: updatedUserMap,
          allUsers: updatedUsers
        };
      });
      setUsersLoading(false);
    } catch (error) {
      setUsersLoading(false);
      console.error('Error activating user:', error);
      throw error;
    }
  }, []);

  const deactivateUser = useCallback(async (userId) => {
    try {
      setUsersLoading(true);
      const functions = getFunctions();
      const deactivateUserFunction = httpsCallable(functions, 'deactivateUser');
      await deactivateUserFunction({ userId, reason: "Admin Deactivated" });

      // Update local state
      setUsersData(prev => {
        const updatedUsers = prev.allUsers.map(user =>
          user.id === userId ? { ...user, isActive: false } : user
        );
        const updatedUserMap = { ...prev.byId };
        updatedUserMap[userId] = { ...updatedUserMap[userId], isActive: false };

        return {
          byId: updatedUserMap,
          allUsers: updatedUsers
        };
      });
      setUsersLoading(false);
    } catch (error) {
      setUsersLoading(false);
      console.error('Error deactivating user:', error);
      throw error;
    }
  }, []);

  const updateUserRoles = useCallback(async (userId, newRoles) => {
    try {
      setUsersLoading(true);
      const functions = getFunctions();
      const updateUserRolesFunction = httpsCallable(functions, 'updateUserRoles');
      await updateUserRolesFunction({ userId, roles: newRoles });

      // Update local state
      setUsersData(prev => {
        const updatedUsers = prev.allUsers.map(user =>
          user.id === userId ? { ...user, roles: newRoles } : user
        );
        const updatedUserMap = { ...prev.byId };
        updatedUserMap[userId] = { ...updatedUserMap[userId], roles: newRoles };

        return {
          byId: updatedUserMap,
          allUsers: updatedUsers
        };
      });
      setUsersLoading(false);
    } catch (error) {
      setUsersLoading(false);
      console.error('Error updating user roles:', error);
      throw error;
    }
  }, []);

  const value = {
    users: usersData.allUsers,
    usersLoading,
    error,
    refetchUsers: () => fetchUsers(true),
    getUserEmail: (id) => usersData.byId[id]?.email || `Unknown [${id}]`,
    getUser: (id) => usersData.byId[id],
    activateUser,
    deactivateUser,
    updateUserRoles
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export function useUsers() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUsers must be used within a UserProvider');
  }
  return context;
}