// dashboard/src/contexts/ProductContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const ProductContext = createContext(null);

// Cache key for localStorage
const CACHE_KEY = 'product_cache';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Helper to get cached data
const getCachedData = () => {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
  } catch (error) {
    console.error('Error reading from cache:', error);
  }
  return null;
};

// Helper to set cached data
const setCachedData = (data) => {
  try {
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error writing to cache:', error);
  }
};

export function ProductProvider({ children }) {
  const [productsData, setProductsData] = useState(() => {
    const cached = getCachedData();
    return cached ? {
      byId: cached.byId,
      allProducts: cached.allProducts,
    } : {
      byId: {},
      allProducts: []
    };
  });
  const [productsLoading, setProductsLoading] = useState(!productsData.allProducts.length);
  const [error, setError] = useState(null);

  const fetchProducts = useCallback(async (force = false) => {
    // Check cache first
    if (!force) {
      const cached = getCachedData();
      if (cached) {
        setProductsData(cached);
        setProductsLoading(false);
        return;
      }
    }

    setProductsLoading(true);
    try {
      const functions = getFunctions();
      const getAllProducts = httpsCallable(functions, 'getAllProducts');
      const response = await getAllProducts({});

      // Store both the array and the lookup object
      const productMap = {};
      response.data.forEach(product => {
        productMap[product.id] = product;
      });

      const newData = {
        byId: productMap,
        allProducts: response.data
      };

      setProductsData(newData);
      setCachedData(newData);
      setError(null);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError(err);
    } finally {
      setProductsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!productsData.allProducts.length) {
      fetchProducts();
    }
  }, [fetchProducts, productsData.allProducts.length]);

  const value = {
    products: productsData.allProducts,
    productsLoading,
    error,
    refetchProducts: () => fetchProducts(true),
    getProductName: (id) => productsData.byId[id]?.name || `Unknown [${id}]`,
    getProduct: (id) => productsData.byId[id]
  };

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  );
}

export function useProducts() {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProducts must be used within a ProductProvider');
  }
  return context;
}