// dashboard/src/components/menus/ProductActionMenu.jsx
import React, { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';

const ProductActionMenu = ({ product }) => {
  const navigate = useNavigate();
  const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
  const buttonRef = useRef(null);

  const updateMenuPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const menuHeight = 48; // Approximate height of menu item

      let top;
      if (spaceBelow < menuHeight) {
        top = rect.top - menuHeight;
      } else {
        top = rect.bottom;
      }
      setMenuPosition({
        // Add window.scrollY to account for scroll position
        top: top,
        right: window.innerWidth - rect.right,
      });
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        ref={buttonRef}
        onClick={updateMenuPosition}
        className="rounded-full p-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" />
      </Menu.Button>

      {createPortal(
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="fixed z-50 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            style={{
              top: `${menuPosition.top}px`,
              right: `${menuPosition.right}px`
            }}
          >
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      navigate(`/dashboard/products/${product.id}/edit`);
                    }}
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } block px-4 py-2 text-sm w-full text-left`}
                  >
                    Edit Product
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>,
        document.body
      )}
    </Menu>
  );
};

export default ProductActionMenu;