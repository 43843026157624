// dashboard/src/components/store/CartItem.jsx
import React from 'react';
import { X } from 'lucide-react';
import { formatCurrency } from '../../utils/Intl';

const CartItem = ({ item, onRemove, colorPalette, currency }) => {
  const totalPrice = item.quantity * item.totalBasePrice;

  return (
    <div className="flex items-start gap-4 py-4 border-b last:border-0"
      style={{ borderColor: colorPalette.textLight + '20' }}>
      <img
        src={item.imageUrl}
        alt={item.name}
        className="w-20 h-20 object-cover rounded"
      />
      <div className="flex-1 min-w-0">
        <h3 className="font-medium">{item.name}</h3>
        {/* Display customizations using the display names/values */}
        {Object.values(item.selectedOptions || {}).map((option) => (
          <div key={option.id} className="text-sm" style={{ color: colorPalette.textLight }}>
            {option.name}: {option.value}
            {option.additionalPrice > 0 && ` (+${formatCurrency(option.additionalPrice, currency)})`}
          </div>
        ))}
        <div className="mt-1 flex items-center gap-2">
          <div className="flex items-center gap-1">
            <span>Qty: {item.quantity}</span>
          </div>
          <div className="flex-1 text-right font-medium">
            {formatCurrency(totalPrice, currency)}
          </div>
        </div>
      </div>
      <button
        onClick={() => onRemove(item.cartId)}
        className="p-1 rounded-full hover:bg-black/5"
        aria-label="Remove item"
      >
        <X size={16} />
      </button>
    </div>
  );
};

export default CartItem;