// dashboard/src/components/common/ImageUpload.jsx
import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CheckCircleIcon, XCircleIcon, PhotoIcon } from '@heroicons/react/24/outline';
import Spinner from './Spinner';


const ImageUpload = ({
  images,
  onImagesChange,
  primaryImageId,
  onPrimaryImageChange
}) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setUploading(true);
    setError('');

    try {
      for (const file of files) {
        // Convert file to base64
        const base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result.split(',')[1]);
          reader.readAsDataURL(file);
        });

        // Upload via Cloud Function
        const functions = getFunctions();
        const uploadProductImage = httpsCallable(functions, 'uploadProductImage');
        const result = await uploadProductImage({
          imageBase64: base64,
          fileName: file.name,
          mimeType: file.type,
          altText: file.name
        });

        const newImage = result.data;
        if (images) {
          onImagesChange([...images, newImage]);
        } else {
          onImagesChange([newImage]);
        }

        // Set as primary if no primary image set
        if (!primaryImageId) {
          onPrimaryImageChange(newImage.id);
        }
      }
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload image. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveImage = async (imageToRemove) => {
    try {
      const functions = getFunctions();
      const deleteProductImage = httpsCallable(functions, 'deleteProductImage');
      await deleteProductImage({ imageId: imageToRemove.id });

      // Update local state
      const updatedImages = images.filter(img => img.id !== imageToRemove.id);
      onImagesChange(updatedImages);

      // Update primary image if needed
      if (imageToRemove.id === primaryImageId) {
        onPrimaryImageChange(updatedImages.length > 0 ? updatedImages[0].id : '');
      }
    } catch (err) {
      console.error('Delete error:', err);
      setError('Failed to remove image. Please try again.');
    }
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
        {/* Existing Images */}
        {images && images.map((image) => (
          <div key={image.id} className="relative group">
            <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-100">
              <img
                src={image.url}
                alt={image.altText || ''}
                className="object-cover object-center"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center space-x-2">
                <button
                  type="button"
                  onClick={() => onPrimaryImageChange(image.id)}
                  className={`p-1 rounded-full ${primaryImageId === image.id
                    ? 'bg-green-500 text-white'
                    : 'bg-white text-gray-900 hover:bg-gray-100'
                    }`}
                  title={primaryImageId === image.id ? 'Primary Image' : 'Set as Primary'}
                >
                  <CheckCircleIcon className="h-5 w-5" />
                </button>
                <button
                  type="button"
                  onClick={() => handleRemoveImage(image)}
                  className="p-1 rounded-full bg-white text-gray-900 hover:bg-gray-100"
                  title="Remove Image"
                >
                  <XCircleIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Upload Button */}
        <div className="aspect-w-1 aspect-h-1 w-full">
          <label className="relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 hover:border-gray-400 cursor-pointer">
            <div className="absolute inset-0 flex items-center justify-center">
              {uploading ? (
                <Spinner className="h-8 w-8 text-indigo-600" />
              ) : (
                <div className="text-center">
                  <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-gray-900">
                    Add Images
                  </span>
                </div>
              )}
            </div>
            <input
              type="file"
              multiple
              accept="image/*"
              className="hidden"
              onChange={handleFileSelect}
              disabled={uploading}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;