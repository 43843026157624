// dashboard/src/components/common/FilterButton.jsx
const FilterButton = ({ label, count, active, onClick }) => (
  <button
    onClick={onClick}
    className={`
      inline-flex items-center px-4 py-2 rounded-full text-sm font-medium mr-2
      ${active
        ? 'bg-indigo-100 text-indigo-700'
        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}
    `}
  >
    {label}
    <span className="ml-2 bg-white rounded-full px-2 py-0.5 text-xs">
      {count}
    </span>
  </button>
);
export default FilterButton;