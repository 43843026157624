// dashboard/src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          // Get the user document from Firestore
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));

          if (userDoc.exists()) {
            const userData = userDoc.data();

            // Create merged user object with all necessary data
            const mergedUser = {
              ...firebaseUser,
              roles: userData.roles || [],
              stripeAccount: userData.stripeAccount || null,
              // Include any other Firestore fields you need
            };

            setUserRoles(userData.roles || []);
            setUser(mergedUser);
          } else {
            setUserRoles([]);
            setUser(firebaseUser);
          }
        } catch (error) {
          console.error('Error fetching user roles:', error);
          setUserRoles([]);
          setUser(firebaseUser);
        }
      } else {
        setUser(null);
        setUserRoles(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, [auth, db]);

  const value = {
    user,
    userRoles,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};