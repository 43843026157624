// dashboard/src/components/layouts/DashboardLayout.jsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {
  HomeIcon,
  UsersIcon,
  MapPinIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  BanknotesIcon,
  PowerIcon
} from '@heroicons/react/24/outline';
import StripeOnboardingBanner from '../stripe/StripeOnboardingBanner';

const DashboardLayout = ({ children }) => {
  const { logout, userRoles, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const navigation = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon },
    {
      name: 'Users',
      href: '/dashboard/users',
      icon: UsersIcon,
      requiresRole: 'admin'
    },
    { name: 'Customers', href: '/dashboard/customers', icon: UsersIcon },
    { name: 'Locations', href: '/dashboard/locations', icon: MapPinIcon },
    { name: 'Products', href: '/dashboard/products', icon: ShoppingBagIcon },
    { name: 'Orders', href: '/dashboard/orders', icon: ShoppingCartIcon },
    { name: 'Finances', href: '/dashboard/finances', icon: BanknotesIcon }
  ].filter(item => !item.requiresRole || userRoles?.includes(item.requiresRole));

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg">
        {/* Top section with logo and logout */}
        <div className="flex h-16 items-center justify-between border-b border-gray-200 px-4">
          <img src="/images/logo.png" alt="Logo" className="h-8" />
          <button
            onClick={handleLogout}
            className="rounded-full p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            title="Sign out"
          >
            <PowerIcon className="h-5 w-5" />
          </button>
        </div>

        <nav className="mt-6">
          <div className="space-y-1 px-2">
            {navigation.map((item) => {
              const isActive = location.pathname === item.href;
              return (
                <a
                  key={item.name}
                  href={item.href}
                  className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive
                    ? 'bg-indigo-50 text-indigo-600'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`}
                >
                  <item.icon
                    className={`mr-3 h-5 w-5 flex-shrink-0 ${isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-gray-500'
                      }`}
                  />
                  {item.name}
                </a>
              );
            })}
          </div>
        </nav>
      </div>

      {/* Main content */}
      <div className="pl-64">
        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">

            {/* Show Stripe onboarding banner for users who need to complete setup */}
            {userRoles?.includes('user') && (
              <StripeOnboardingBanner stripeAccount={user?.stripeAccount} />
            )}
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;