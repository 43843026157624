// dashboard/src/components/ui/Table.jsx
import { cn } from "../../utils/ui";

export const Table = ({ children, className, ...props }) => (
  <div className="mt-8 flow-root">
    <div className="inline-block min-w-full py-2 align-middle">
      <table
        className={cn("min-w-full divide-y divide-gray-300", className)}
        {...props}
      >
        {children}
      </table>
    </div>
  </div>
);

export const TableHeader = ({ children }) => (
  <thead className="bg-gray-50">
    {children}
  </thead>
);

export const TableBody = ({ children, className, ...props }) => (
  <tbody
    className={cn("divide-y divide-gray-200 bg-white", className)}
    {...props}
  >
    {children}
  </tbody>
);

export const TableHeaderCell = ({ children, onClick }) => (
  <th
    className={cn(
      "px-6 py-2 text-left text-sm font-semibold text-gray-900",
      onClick && "cursor-pointer hover:bg-gray-50"
    )}
    onClick={onClick}
  >
    {children}
  </th>
);

export const SortableTableHeaderCell = ({ onClick, isSorted, sortDirection, children }) => (
  <TableHeaderCell onClick={onClick}
  >
    <div className="flex items-center">
      {children}
      {isSorted && (
        <span className="ml-2">
          {sortDirection === 'asc' ? '↑' : '↓'}
        </span>
      )}
    </div>
  </TableHeaderCell>
);

export const TableRow = ({ children }) => (
  <tr className="hover:bg-gray-50">
    {children}
  </tr>
);

export const TableCell = ({ children }) => (
  <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
    {children}
  </td>
);