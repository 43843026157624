// dashboard/src/components/common/DetailTooltip.js
import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';

const DetailTooltip = ({ trigger, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);

  const updatePosition = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const scrollY = window.scrollY;
      const scrollX = window.scrollX;

      // Position the tooltip above the trigger element
      setPosition({
        top: rect.top + scrollY - 10, // 10px above the trigger
        left: rect.left + scrollX + (rect.width / 2) // Centered horizontally
      });
    }
  };

  const handleMouseEnter = () => {
    updatePosition();
    setIsVisible(true);
  };

  return (
    <div
      ref={triggerRef}
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsVisible(false)}
    >
      {trigger}
      {isVisible && createPortal(
        <div
          className="fixed z-50"
          style={{
            transform: 'translateX(-50%) translateY(-100%)',
            top: `${position.top}px`,
            left: `${position.left}px`
          }}
        >
          <div className="bg-gray-900 text-white rounded px-3 py-2 text-sm shadow-lg whitespace-normal max-w-sm">
            {content}
            <div
              className="absolute left-1/2 -translate-x-1/2 top-full"
              style={{
                borderWidth: '4px',
                borderStyle: 'solid',
                borderColor: 'rgb(17 24 39) transparent transparent transparent'
              }}
            />
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default DetailTooltip;