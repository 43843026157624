// dashboard/src/components/store/Cart.jsx
import React, { useState } from 'react';
import { X } from 'lucide-react';
import CartItem from './CartItem';
import { formatCurrency } from '../../utils/Intl';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Alert, AlertDescription } from '../ui/alert';
import Spinner from '../common/Spinner';

const Cart = ({ isOpen, onClose, items, onRemoveItem, colorPalette, currency, storeUrl }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const cartTotal = items.reduce((sum, item) => {
    const itemTotal = item.quantity * (
      item.basePrice +
      Object.values(item.selectedOptions || {})
        .reduce((sum, opt) => sum + (opt.additionalPrice || 0), 0)
    );
    return sum + itemTotal;
  }, 0);

  const handleCheckout = async () => {
    setLoading(true);
    setError('');

    try {
      // Transform cart items to the expected format
      const cartItems = items.map(item => {
        const cartItem = {
          productId: item.productId,
          quantity: item.quantity,
        };

        // Add customizations if they exist
        if (item.selectedOptions) {
          cartItem.selectedCustomizations = Object.values(item.selectedOptions).map(option => ({
            customizationId: option.id,        // Use the stored customization ID
            optionId: option.optionId          // Use the stored option ID
          }));
        }

        return cartItem;
      });

      const functions = getFunctions();
      const createStripeOrder = httpsCallable(functions, 'createStripeOrder');

      const { data: { checkoutUrl, orderId } } = await createStripeOrder({
        storeUrl,
        cart: cartItems,
        successUrl: `${window.location.origin}/store/${storeUrl}/success`,
        cancelUrl: window.location.href
      });

      // Store the orderId before redirect
      localStorage.setItem(`${storeUrl}_pending_order`, orderId);

      // Redirect to Stripe Checkout
      window.location.href = checkoutUrl;
    } catch (err) {
      console.error('Checkout error:', err);
      setError('Failed to start checkout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={`fixed inset-y-0 right-0 w-full sm:w-96 z-30 transform transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}>
        <div className="h-full shadow-xl" style={{ backgroundColor: colorPalette.background }}>
          <div className="flex items-center justify-between p-4 border-b"
            style={{ borderColor: colorPalette.textLight + '20' }}>
            <h2 className="text-lg font-semibold">Shopping Cart</h2>
            <button
              onClick={onClose}
              className="p-2 rounded-full hover:bg-black/5"
            >
              <X size={20} />
            </button>
          </div>

          {error && (
            <Alert variant="destructive" className="m-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <div className="p-4 flex-1 overflow-y-auto">
            {items.length === 0 ? (
              <div className="text-center py-8" style={{ color: colorPalette.textLight }}>
                Your cart is empty
              </div>
            ) : (
              items.map(item => (
                <CartItem
                  key={item.cartId}
                  item={item}
                  onRemove={onRemoveItem}
                  colorPalette={colorPalette}
                  currency={currency}
                />
              ))
            )}
          </div>

          {items.length > 0 && (
            <div className="p-4 border-t" style={{ borderColor: colorPalette.textLight + '20' }}>
              <div className="flex items-center justify-between mb-4">
                <span className="font-medium">Total:</span>
                <span className="text-xl font-bold" style={{ color: colorPalette.accent }}>
                  {formatCurrency(cartTotal, currency)}
                </span>
              </div>
              <button
                className="w-full py-3 rounded-lg text-white font-medium disabled:opacity-50"
                style={{ backgroundColor: colorPalette.primary }}
                onClick={handleCheckout}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <Spinner className="w-4 h-4 mr-2" />
                    Processing...
                  </div>
                ) : (
                  'Checkout'
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-20"
          onClick={onClose}
        />
      )}
    </>
  );
};

export default Cart;