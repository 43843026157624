// dashboard/src/pages/Users.jsx
import React, { useState, useMemo } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Spinner from '../components/common/Spinner'
import FilterButton from '../components/common/FilterButton'
import { formatTimestamp } from '../utils/utils'
import UserActionMenu from '../components/menus/UserActionMenu'
import { useUsers } from '../contexts/UserContext';
import { Table, TableHeader, SortableTableHeaderCell, TableHeaderCell, TableBody, TableRow, TableCell } from '../components/ui/Table';

const UsersPage = () => {
  const {
    users,
    usersLoading,
    activateUser,
    deactivateUser,
    updateUserRoles
  } = useUsers();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [sortField, setSortField] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc');

  // Filter counts
  const filterCounts = useMemo(() => {
    return {
      all: users.length,
      active: users.filter(user => user.isActive).length,
      needsValidation: users.filter(user => user.roles.includes('user')).length
    };
  }, [users]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const SortHeader = ({ field, children }) => (
    <SortableTableHeaderCell onClick={() => handleSort(field)}
      isSorted={sortField === field} sortDirection={sortDirection}>
      {children}
    </SortableTableHeaderCell>
  );

  const setActiveFilterAndResetPage = (filter => {
    setActiveFilter(filter);
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Search Bar */}
      <div className="relative mb-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search users by email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Title */}
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Users</h1>

      {/* Filter Buttons */}
      <div className="mb-6">
        <FilterButton
          label="All"
          count={filterCounts.all}
          active={activeFilter === 'all'}
          onClick={() => setActiveFilterAndResetPage('all')}
        />
        <FilterButton
          label="Active"
          count={filterCounts.active}
          active={activeFilter === 'active'}
          onClick={() => setActiveFilterAndResetPage('active')}
        />
        <FilterButton
          label="Needs Validation"
          count={filterCounts.needsValidation}
          active={activeFilter === 'needsValidation'}
          onClick={() => setActiveFilterAndResetPage('needsValidation')}
        />
      </div>

      {/* Users Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {usersLoading ? (
              <div className="flex justify-center items-center min-h-[400px]">
                <Spinner className="h-8 w-8 text-indigo-600" />
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <SortHeader field="id">ID</SortHeader>
                    <SortHeader field="email">Email</SortHeader>
                    <SortHeader field="roles">Roles</SortHeader>
                    <SortHeader field="isActive">Status</SortHeader>
                    <SortHeader field="lastLoginAt">Last Login</SortHeader>
                    <TableHeaderCell className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <div className="flex gap-1">
                          {user.roles.map(role => (
                            <span
                              key={role}
                              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${role === 'admin'
                                ? 'bg-purple-100 text-purple-800'
                                : role === 'staff'
                                  ? 'bg-blue-100 text-blue-800'
                                  : 'bg-gray-100 text-gray-800'
                                }`}
                            >
                              {role.charAt(0).toUpperCase() + role.slice(1)}
                            </span>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${user.isActive
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                          }`}>
                          {user.isActive ? 'Active' : 'Inactive'}
                        </span>
                      </TableCell>
                      <TableCell>{formatTimestamp(user.lastLoginAt)}</TableCell>
                      <TableCell>
                        <UserActionMenu
                          user={user}
                          onActivate={() => activateUser(user.id)}
                          onDeactivate={() => deactivateUser(user.id)}
                          onUpdateRoles={(newRoles) => updateUserRoles(user.id, newRoles)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;