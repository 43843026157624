// dashboard/src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBFz2pjl5Hs5K5P-iBLe5nYYWdl2Oq_lsc",
  authDomain: "stripebarista.firebaseapp.com",
  projectId: "stripebarista",
  storageBucket: "stripebarista.firebasestorage.app",
  messagingSenderId: "1055706967887",
  appId: "1:1055706967887:web:d128dbd0b9b99d90301f3a",
  measurementId: "G-3D515HEDX9"
};

initializeApp(firebaseConfig);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);