// dashboard/src/hooks/useCart.js
import { useState, useCallback, useEffect } from 'react';

const CART_STORAGE_PREFIX = 'store_cart_';
const CART_EXPIRY_MS = 30 * 60 * 1000; // 30 minutes in milliseconds

const getStorageKey = (storeUrl) => `${CART_STORAGE_PREFIX}${storeUrl}`;

const useCart = (storeUrl) => {
  // Initialize state from localStorage if available
  const [items, setItems] = useState(() => {
    if (typeof window === 'undefined') return [];

    try {
      const storageKey = getStorageKey(storeUrl);
      const storedCart = localStorage.getItem(storageKey);

      if (storedCart) {
        const { items, timestamp } = JSON.parse(storedCart);
        const isExpired = Date.now() - timestamp > CART_EXPIRY_MS;

        if (!isExpired) {
          return items;
        } else {
          // Clean up expired cart
          localStorage.removeItem(storageKey);
        }
      }
    } catch (error) {
      console.error('Error reading cart from localStorage:', error);
    }

    return [];
  });

  const [isOpen, setIsOpen] = useState(false);

  // Persist cart to localStorage whenever it changes
  useEffect(() => {
    if (typeof window === 'undefined' || !storeUrl) return;

    try {
      const storageKey = getStorageKey(storeUrl);
      const cartData = {
        items,
        timestamp: Date.now()
      };
      localStorage.setItem(storageKey, JSON.stringify(cartData));
    } catch (error) {
      console.error('Error saving cart to localStorage:', error);
    }
  }, [items, storeUrl]);

  // Clean up expired carts on mount
  useEffect(() => {
    if (typeof window === 'undefined') return;

    try {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith(CART_STORAGE_PREFIX)) {
          const storedCart = localStorage.getItem(key);
          if (storedCart) {
            const { timestamp } = JSON.parse(storedCart);
            const isExpired = Date.now() - timestamp > CART_EXPIRY_MS;
            if (isExpired) {
              localStorage.removeItem(key);
            }
          }
        }
      });
    } catch (error) {
      console.error('Error cleaning up expired carts:', error);
    }
  }, []);

  const addItem = useCallback((newItem) => {
    setItems(prev => {
      // Check if this exact item (including customizations) already exists
      const existingItemIndex = prev.findIndex(item => {
        if (item.productId !== newItem.productId) return false;

        // If either item has customizations, compare them
        if (item.selectedOptions || newItem.selectedOptions) {
          return JSON.stringify(item.selectedOptions) === JSON.stringify(newItem.selectedOptions);
        }

        // If no customizations, items match
        return true;
      });

      if (existingItemIndex >= 0) {
        // Update quantity of existing item
        const updatedItems = [...prev];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: updatedItems[existingItemIndex].quantity + newItem.quantity
        };
        return updatedItems;
      }

      // Add as new item if no match found
      return [...prev, newItem];
    });
    setIsOpen(true);
  }, []);

  const removeItem = useCallback((cartId) => {
    setItems(prev => prev.filter(item => item.cartId !== cartId));
  }, []);

  const clearCart = useCallback(() => {
    setItems([]);
    if (typeof window !== 'undefined' && storeUrl) {
      localStorage.removeItem(getStorageKey(storeUrl));
    }
  }, [storeUrl]);

  const toggleCart = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return {
    items,
    isOpen,
    addItem,
    removeItem,
    clearCart,
    toggleCart,
    setIsOpen
  };
};

export default useCart;