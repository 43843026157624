// dashboard/src/pages/Dashboard.jsx
import React from 'react';
import { useAuth } from '../hooks/useAuth';

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900">Welcome to your Dashboard</h1>
      <p className="mt-2 text-gray-600">Logged in as: {user?.email}</p>

      <div className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <h3 className="text-lg font-medium text-gray-900">Quick Stats</h3>
            <p className="mt-1 text-gray-500">Coming soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;