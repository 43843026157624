// dashboard/src/pages/Orders.jsx
import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Spinner from '../components/common/Spinner';
import FilterButton from '../components/common/FilterButton';
import { formatTimestamp } from '../utils/utils';
import DetailTooltip from '../components/common/DetailTooltip';
import { formatCurrency } from '../utils/Intl';
import { useLocations } from '../contexts/LocationContext';
import { Table, TableHeader, SortableTableHeaderCell, TableHeaderCell, TableBody, TableRow, TableCell } from '../components/ui/Table';
import { useOrders } from '../hooks/useOrders';

const LocationFilter = ({ locations, selectedLocation, onChange }) => (
  <select
    value={selectedLocation || ''}
    onChange={(e) => onChange(e.target.value || undefined)}
    className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  >
    <option value="">All Locations</option>
    {locations.map(location => (
      <option key={location.id} value={location.id}>
        {location.description}
      </option>
    ))}
  </select>
);

const ItemDetails = ({ items, currency }) => {
  if (!items || Object.keys(items).length === 0) return "No items set";
  return (
    <div className="space-y-1">
      {items.map((item) => (
        <div key={item.productId}>
          {item.quantity} x {item.productSnapshot ? item.productSnapshot.name : ""}: {formatCurrency(item.totalPrice, currency)}
        </div>
      ))}
    </div>
  );
};

const OrdersPage = () => {
  const { locations, locationsLoading, getLocationName } = useLocations();
  const {
    state,
    orders,
    loading,
    updateSearchTerm,
    updateFilter,
    updateSort,
    updateLocation,
    handleNextPage,
    handlePrevPage,
    filterCounts
  } = useOrders();

  const SortHeader = ({ field, children }) => (
    <SortableTableHeaderCell
      onClick={() => updateSort(field)}
      isSorted={state.sortField === field}
      sortDirection={state.sortDirection}
    >
      {children}
    </SortableTableHeaderCell>
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Search Bar */}
      <div className="relative mb-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search orders by item name..."
          value={state.searchTerm}
          onChange={(e) => updateSearchTerm(e.target.value)}
        />
      </div>

      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        Orders {state.selectedLocation && `(${locations.find(l => l.id === state.selectedLocation)?.description})`}
      </h1>

      {/* Filter Buttons */}
      <div className="mb-6">
        <FilterButton
          label="Has Customer"
          count={filterCounts.hasCustomer}
          active={state.hasCustomerFilter}
          onClick={() => updateFilter(!state.hasCustomerFilter)}
        />
      </div>

      {/* Orders Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading || locationsLoading ? (
              <div className="flex justify-center items-center min-h-[400px]">
                <Spinner className="h-8 w-8 text-indigo-600" />
              </div>
            ) : orders.length === 0 ? (
              <div className="text-center py-12 text-gray-500">
                No orders found
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <SortHeader field="totalPrice">Total</SortHeader>
                    <TableHeaderCell>
                      <LocationFilter
                        locations={locations}
                        selectedLocation={state.selectedLocation}
                        onChange={updateLocation}
                      />
                    </TableHeaderCell>
                    <SortHeader field="status">Status</SortHeader>
                    <SortHeader field="id">Order ID</SortHeader>
                    <SortHeader field="itemCount">Items</SortHeader>
                    <TableHeaderCell>Customer</TableHeaderCell>
                    <SortHeader field="createdAt">Date</SortHeader>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell className="text-right">
                        <b>{formatCurrency(order.total, order.currency)}</b>
                      </TableCell>
                      <TableCell className="text-gray-900">
                        {getLocationName(order.locationId)}
                      </TableCell>
                      <TableCell>
                        <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${order.status === "ready" ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                          }`}>
                          {order.status}
                        </span>
                      </TableCell>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>
                        <DetailTooltip
                          trigger={
                            <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-gray-100 text-gray-800">
                              {order.items.length} items
                            </span>
                          }
                          content={<ItemDetails items={order.items} currency={order.currency} />}
                        />
                      </TableCell>
                      <TableCell>
                        {order.customer?.email ? order.customer.email : 'No'}
                      </TableCell>
                      <TableCell>
                        {formatTimestamp(order.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="mt-6 flex items-center justify-between">
        <div className="text-sm text-gray-700">
          {orders.length > 1 ? (
            <span>
              Showing orders {(state.pagination.currentPage - 1) * state.itemsPerPage + 1} - {(state.pagination.currentPage - 1) * state.itemsPerPage + orders.length}
            </span>
          ) : orders.length === 1 ? (
            <span>
              Showing order {(state.pagination.currentPage - 1) * state.itemsPerPage + 1}
            </span>
          ) : (
            <span>
              No orders
            </span>
          )}
          &nbsp; • Page {state.pagination.currentPage}
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={state.pagination.currentPage === 1 || loading}
            className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={!state.pagination.hasMore || loading}
            className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;