// dashboard/src/components/stripe/StripeOnboardingBanner.jsx
import React, { useState } from 'react';
import { Alert, AlertTitle, AlertDescription } from '../ui/alert';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Spinner from '../common/Spinner';

const StripeOnboardingBanner = ({ stripeAccount }) => {
  const [loading, setLoading] = useState(false);

  const handleStartOnboarding = async () => {
    setLoading(true);
    try {
      const functions = getFunctions();

      // Create Stripe account if not exists
      if (!stripeAccount?.connectedAccountId) {
        await httpsCallable(functions, 'createStripeAccount')({});
      }

      // Get account link
      const getAccountLink = httpsCallable(functions, 'getStripeAccountLink');
      const { data: { url } } = await getAccountLink({
        refreshUrl: window.location.href,
        returnUrl: `${window.location.origin}/dashboard`
      });

      // Redirect to Stripe
      window.location.href = url;
    } catch (error) {
      console.error('Error starting onboarding:', error);
    }
    setLoading(false);
  };

  if (!stripeAccount || stripeAccount.status === 'active') {
    return null;
  }

  const messages = {
    pending: {
      title: 'Set Up Your Stripe Account',
      description: 'To start accepting payments, you need to complete your Stripe account setup.',
      action: 'Start Setup'
    },
    onboarding: {
      title: 'Complete Your Stripe Account Setup',
      description: 'You need to complete your Stripe account setup to continue accepting payments.',
      action: 'Continue Setup'
    },
    restricted: {
      title: 'Stripe Account Restricted',
      description: 'Your Stripe account has been restricted. Please complete the verification process.',
      action: 'Complete Verification'
    },
    rejected: {
      title: 'Stripe Account Rejected',
      description: 'Your Stripe account application was rejected. Please contact support.',
      action: 'Contact Support'
    },
    disabled: {
      title: 'Stripe Account Disabled',
      description: 'Your Stripe account has been disabled. Please contact support.',
      action: 'Contact Support'
    }
  };

  const message = messages[stripeAccount.status];
  return (
    <Alert variant="destructive" className="mb-6">
      <AlertTitle>{message.title}</AlertTitle>
      <AlertDescription>
        <div className="flex items-center justify-between">
          <span>{message.description}</span>
          <button
            onClick={handleStartOnboarding}
            disabled={loading || ['rejected', 'disabled'].includes(stripeAccount.status)}
            className="bg-white text-red-600 px-4 py-2 rounded-md text-sm font-medium hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {loading ? (
              <div className="flex items-center">
                <Spinner className="w-4 h-4 mr-2" />
                Loading...
              </div>
            ) : (
              message.action
            )}
          </button>
        </div>
      </AlertDescription>
    </Alert>
  );
};

export default StripeOnboardingBanner;