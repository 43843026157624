// dashboard/src/components/customers/CustomerOrdersModal.jsx
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { formatCurrency } from '../../utils/Intl';
import Spinner from '../common/Spinner';
import { formatTimestamp } from '../../utils/utils'
import { useLocations } from '../../contexts/LocationContext';
import { Table, TableHeader, TableHeaderCell, TableBody, TableRow, TableCell } from '../ui/Table';


const CustomerOrdersModal = ({ customer, isOpen, onClose }) => {
  const { getLocationName, locationsLoading } = useLocations();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);

  const fetchOrders = async (startAfter = null) => {
    const isInitialFetch = !startAfter;
    if (isInitialFetch) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    setError(null);

    try {
      const functions = getFunctions();
      const getCustomerOrders = httpsCallable(functions, 'getCustomerOrders');
      const response = await getCustomerOrders({
        customerId: customer.id,
        limit: 10,
        startAfter
      });

      if (isInitialFetch) {
        setOrders(response.data.orders);
      } else {
        setOrders(prev => [...prev, ...response.data.orders]);
      }
      setHasMore(response.data.hasMore);
    } catch (err) {
      console.error('Error fetching customer orders:', err);
      setError('Failed to load orders');
    } finally {
      if (isInitialFetch) {
        setLoading(false);
      } else {
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen && customer) {
      fetchOrders();
    }
  }, [customer, isOpen]);

  const handleLoadMore = () => {
    if (!loadingMore && hasMore && orders.length > 0) {
      fetchOrders(orders[orders.length - 1].id);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose} />

        {/* Modal panel */}
        <div className="inline-block w-full max-w-5xl px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:p-6">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={onClose}
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-900">
              Orders for {customer.name || 'Guest Customer'}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {customer.primaryEmail && `Email: ${customer.primaryEmail}`}
              {customer.primaryPhone && ` • Phone: ${customer.primaryPhone}`}
            </p>
          </div>

          {error ? (
            <div className="p-4 text-sm text-red-700 bg-red-100 rounded">
              {error}
            </div>
          ) : loading || locationsLoading ? (
            <div className="flex items-center justify-center h-48">
              <Spinner className="w-8 h-8 text-indigo-600" />
            </div>
          ) : orders.length === 0 ? (
            <div className="py-8 text-center text-gray-500">
              No orders found for this customer
            </div>
          ) : (
            <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Order ID</TableHeaderCell>
                    <TableHeaderCell>Date</TableHeaderCell>
                    <TableHeaderCell>Location</TableHeaderCell>
                    <TableHeaderCell>Items</TableHeaderCell>
                    <TableHeaderCell>Total</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{formatTimestamp(order.createdAt)}</TableCell>
                      <TableCell>{getLocationName(order.locationId)}</TableCell>
                      <TableCell>
                        <div className="max-w-xs truncate">
                          {order.items.map((item, index) => (
                            <span key={index}>
                              {index > 0 && ', '}
                              {item.quantity}x {item.productSnapshot.name}
                            </span>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>{formatCurrency(order.total, order.currency)}</TableCell>
                      <TableCell>
                        <span className={`inline-flex px-2 text-xs font-semibold leading-5 rounded-full
                          ${order.status === 'completed' ? 'bg-green-100 text-green-800' :
                            order.status === 'cancelled' ? 'bg-red-100 text-red-800' :
                              'bg-yellow-100 text-yellow-800'}`}>
                          {order.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          {hasMore && !(loading || locationsLoading) && (
            <div className="flex justify-center mt-4">
              <button
                type="button"
                onClick={handleLoadMore}
                disabled={loadingMore}
                className="px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loadingMore ? (
                  <div className="flex items-center">
                    <Spinner className="w-4 h-4 mr-2" />
                    Loading...
                  </div>
                ) : (
                  'Load More'
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerOrdersModal;