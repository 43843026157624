// dashboard/src/hooks/useOrders.js  
import { useReducer, useEffect, useMemo } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const initialState = {
  orders: [],
  loading: true,
  searchTerm: '',
  hasCustomerFilter: false,
  selectedLocation: undefined,
  sortField: 'createdAt',
  sortDirection: 'desc',
  itemsPerPage: 20,
  pagination: {
    currentPage: 1,
    hasMore: true,
    cursors: [null],
  }
};

const actionTypes = {
  SET_ORDERS: 'SET_ORDERS',
  SET_LOADING: 'SET_LOADING',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  UPDATE_SORT: 'UPDATE_SORT',
  UPDATE_PAGINATION: 'UPDATE_PAGINATION',
  RESET_PAGINATION: 'RESET_PAGINATION',
};

function ordersReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_ORDERS:
      return {
        ...state,
        orders: action.payload
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actionTypes.UPDATE_SEARCH:
      return {
        ...state,
        searchTerm: action.payload,
        pagination: initialState.pagination
      };
    case actionTypes.UPDATE_FILTER:
      return {
        ...state,
        hasCustomerFilter: action.payload,
        pagination: initialState.pagination
      };
    case actionTypes.UPDATE_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
        pagination: initialState.pagination
      };
    case actionTypes.UPDATE_SORT:
      const { field } = action.payload;
      const direction = field === state.sortField
        ? (state.sortDirection === 'asc' ? 'desc' : 'asc')
        : 'desc';
      return {
        ...state,
        sortField: field,
        sortDirection: direction,
        pagination: initialState.pagination
      };
    case actionTypes.UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    case actionTypes.RESET_PAGINATION:
      return {
        ...state,
        pagination: initialState.pagination
      };
    default:
      return state;
  }
}

export function useOrders() {
  const [state, dispatch] = useReducer(ordersReducer, initialState);

  const fetchOrders = async () => {
    const lastOrderId = state.pagination.cursors[state.pagination.currentPage - 1];

    dispatch({ type: actionTypes.SET_LOADING, payload: true });

    try {
      const functions = getFunctions();
      const getOrders = httpsCallable(functions, 'getOrders');

      const response = await getOrders({
        locationId: state.selectedLocation,
        sortField: state.sortField,
        sortDirection: state.sortDirection,
        limit: state.itemsPerPage,
        lastOrderId,
        searchTerm: state.searchTerm || undefined,
        hasCustomer: state.hasCustomerFilter || undefined
      });

      dispatch({ type: actionTypes.SET_ORDERS, payload: response.data.orders });

      if (response.data.orders.length > 0 && response.data.hasMore) {
        const nextCursor = response.data.orders[response.data.orders.length - 1].id;
        const lastCursor = state.pagination.cursors[state.pagination.cursors.length - 1];

        if (lastCursor !== nextCursor) {
          dispatch({
            type: actionTypes.UPDATE_PAGINATION,
            payload: {
              hasMore: response.data.hasMore,
              cursors: [...state.pagination.cursors, nextCursor]
            }
          });
        } else {
          dispatch({
            type: actionTypes.UPDATE_PAGINATION,
            payload: { hasMore: response.data.hasMore }
          });
        }
      } else {
        dispatch({
          type: actionTypes.UPDATE_PAGINATION,
          payload: { hasMore: response.data.hasMore }
        });
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  // Fetch orders when relevant state changes
  useEffect(() => {
    fetchOrders();
  }, [
    state.selectedLocation,
    state.hasCustomerFilter,
    state.searchTerm,
    state.sortField,
    state.sortDirection,
    state.pagination.currentPage
  ]);

  const filterCounts = useMemo(() => ({
    hasCustomer: state.orders.filter(order => order.hasCustomer).length
  }), [state.orders]);

  return {
    state,
    orders: state.orders,
    loading: state.loading,
    filterCounts,
    updateSearchTerm: (term) => dispatch({
      type: actionTypes.UPDATE_SEARCH,
      payload: term
    }),
    updateFilter: (value) => dispatch({
      type: actionTypes.UPDATE_FILTER,
      payload: value
    }),
    updateLocation: (location) => dispatch({
      type: actionTypes.UPDATE_LOCATION,
      payload: location
    }),
    updateSort: (field) => dispatch({
      type: actionTypes.UPDATE_SORT,
      payload: { field }
    }),
    handleNextPage: () => {
      if (state.pagination.hasMore) {
        dispatch({
          type: actionTypes.UPDATE_PAGINATION,
          payload: { currentPage: state.pagination.currentPage + 1 }
        });
      }
    },
    handlePrevPage: () => {
      if (state.pagination.currentPage > 1) {
        dispatch({
          type: actionTypes.UPDATE_PAGINATION,
          payload: { currentPage: state.pagination.currentPage - 1 }
        });
      }
    }
  };
}