// dashboard/src/pages/ProductForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import ImageUpload from '../components/common/ImageUpload';
import { Alert, AlertDescription } from '../components/ui/alert';
import { currencies } from '../utils/Intl';
import Spinner from '../components/common/Spinner';
import { useProducts } from '../contexts/ProductContext';

const ProductForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getProduct, refetchProducts } = useProducts();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    basePrices: { USD: '' },
    customizations: [],
    images: [],
    primaryImageId: '',
    isActive: true,
    categories: ['']
  });

  useEffect(() => {
    const fetchProduct = async () => {
      if (!id) return;

      setLoading(true);
      try {
        const functions = getFunctions();
        const product = getProduct(id);
        const formattedPrices = {};
        Object.entries(product.basePrices || {}).forEach(([currency, price]) => {
          formattedPrices[currency] = (price / 100).toString();
        });

        setFormData({
          name: product.name || '',
          description: product.description || '',
          basePrices: formattedPrices,
          customizations: product.customizations || [],
          primaryImageId: product.primaryImageId || '',
          isActive: product.isActive ?? true,
          categories: product.categories?.length ? product.categories : ['']
        });

        if (product.images?.length) {
          const getProductImages = httpsCallable(functions, 'getProductImages');
          const imagesResponse = await getProductImages({
            imageIds: product.images
          });

          // Just update formData with the received images
          setFormData(prev => ({
            ...prev,
            images: imagesResponse.data,
            primaryImageId: product.primaryImageId || ''
          }));
        }

        // ... rest of product data setting
      } catch (err) {
        console.error('Error fetching product:', err);
        setError('Failed to load product details');
      }
      setLoading(false);
    };

    fetchProduct();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Convert price strings to numbers (in cents)
      const processedPrices = {};
      Object.entries(formData.basePrices).forEach(([currency, price]) => {
        if (price) {
          processedPrices[currency] = Math.round(parseFloat(price) * 100);
        }
      });

      // Clean up empty categories
      const cleanedCategories = formData.categories.filter(cat => cat.trim());

      // Get image IDs directly from formData.images
      const imageIds = formData.images.map(img => img.id);

      // Prepare customizations with proper price formatting
      const processedCustomizations = formData.customizations.map(custom => ({
        ...custom,
        options: custom.options.map(opt => {
          // Process additional prices, omitting empty or invalid ones
          const processedAdditionalPrices = {};
          if (opt.additionalPrice) {
            Object.entries(opt.additionalPrice).forEach(([curr, price]) => {
              if (price && !isNaN(parseFloat(price))) {
                processedAdditionalPrices[curr] = Math.round(parseFloat(price) * 100);
              }
            });
          }

          return {
            ...opt,
            ...(Object.keys(processedAdditionalPrices).length > 0 && {
              additionalPrice: processedAdditionalPrices
            })
          };
        })
      }));

      const productData = {
        ...formData,
        basePrices: processedPrices,
        categories: cleanedCategories,
        customizations: processedCustomizations,
        images: imageIds // Use the IDs from formData.images
      };

      const functions = getFunctions();
      const functionName = id ? 'updateProduct' : 'createProduct';
      const cloudFunction = httpsCallable(functions, functionName);

      await cloudFunction({
        ...productData,
        productId: id,
      });

      refetchProducts();
      navigate('/dashboard/products');
    } catch (err) {
      setError(err.message || 'An error occurred while saving the product');
      console.error('Error saving product:', err);
    }
    setLoading(false);
  };

  const addCategory = () => {
    setFormData(prev => ({
      ...prev,
      categories: [...prev.categories, '']
    }));
  };

  const removeCategory = (index) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.filter((_, i) => i !== index)
    }));
  };

  const updateCategory = (index, value) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.map((cat, i) =>
        i === index ? value : cat
      )
    }));
  };

  const addCustomization = () => {
    setFormData(prev => ({
      ...prev,
      customizations: [...prev.customizations, {
        name: '',
        options: [{
          value: '',
          additionalPrice: {},
          isDefault: true
        }]
      }]
    }));
  };

  const removeCustomization = (index) => {
    setFormData(prev => ({
      ...prev,
      customizations: prev.customizations.filter((_, i) => i !== index)
    }));
  };

  const updateCustomization = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      customizations: prev.customizations.map((custom, i) =>
        i === index ? { ...custom, [field]: value } : custom
      )
    }));
  };

  const addCustomizationOption = (customIndex) => {
    setFormData(prev => ({
      ...prev,
      customizations: prev.customizations.map((custom, i) =>
        i === customIndex ? {
          ...custom,
          options: [...custom.options, {
            value: '',
            additionalPrice: {},
            isDefault: false
          }]
        } : custom
      )
    }));
  };

  const removeCustomizationOption = (customIndex, optionIndex) => {
    setFormData(prev => ({
      ...prev,
      customizations: prev.customizations.map((custom, i) =>
        i === customIndex ? {
          ...custom,
          options: custom.options.filter((_, j) => j !== optionIndex)
        } : custom
      )
    }));
  };

  const updateCustomizationOption = (customIndex, optionIndex, field, value) => {
    setFormData(prev => ({
      ...prev,
      customizations: prev.customizations.map((custom, i) =>
        i === customIndex ? {
          ...custom,
          options: custom.options.map((option, j) =>
            j === optionIndex ? { ...option, [field]: value } : option
          )
        } : custom
      )
    }));
  };

  if (loading && id) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Spinner className="h-8 w-8 text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        {id ? 'Edit Product' : 'Create New Product'}
      </h1>

      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name *
              </label>
              <input
                type="text"
                id="name"
                required
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Status</label>
              <div className="mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={formData.isActive}
                    onChange={(e) => setFormData(prev => ({ ...prev, isActive: e.target.checked }))}
                  />
                  <span className="ml-2 text-sm text-gray-600">Active</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Pricing</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {currencies.map(currency => (
              <div key={currency.code}>
                <label className="block text-sm font-medium text-gray-700">
                  {currency.label} Price
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                      {currency.code === 'USD' ? '$' : currency.code}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    className="mt-1 block w-full rounded-md border border-gray-300 pl-10 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={formData.basePrices[currency.code] || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      basePrices: {
                        ...prev.basePrices,
                        [currency.code]: e.target.value
                      }
                    }))}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Categories */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Categories</h2>
          <div className="space-y-2">
            {formData.categories.map((category, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  className="flex-1 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={category}
                  onChange={(e) => updateCategory(index, e.target.value)}
                  placeholder="Enter category"
                />
                {formData.categories.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeCategory(index)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addCategory}
              className="mt-2 inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500"
            >
              <PlusIcon className="h-4 w-4 mr-1" />
              Add Category
            </button>
          </div>
        </div>

        {/* Customizations */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Customizations</h2>
          <div className="space-y-6">
            {formData.customizations.map((customization, customIndex) => (
              <div key={customIndex} className="border rounded-md p-4">
                <div className="flex justify-between items-center mb-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={customization.name}
                    onChange={(e) => updateCustomization(customIndex, 'name', e.target.value)}
                    placeholder="Customization name (e.g., Size, Extra Shot)"
                  />
                  <button
                    type="button"
                    onClick={() => removeCustomization(customIndex)}
                    className="ml-2 text-gray-400 hover:text-gray-500"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="space-y-3">
                  {customization.options.map((option, optionIndex) => (
                    <div key={optionIndex} className="flex gap-2 items-start">
                      <input
                        type="text"
                        className="flex-1 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={option.value}
                        onChange={(e) => updateCustomizationOption(customIndex, optionIndex, 'value', e.target.value)}
                        placeholder="Option value (e.g., Small, Medium, Large)"
                      />

                      <div className="flex-1">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          value={option.additionalPrice?.USD || ''}
                          onChange={(e) => updateCustomizationOption(
                            customIndex,
                            optionIndex,
                            'additionalPrice',
                            { ...option.additionalPrice, USD: e.target.value }
                          )}
                          placeholder="Additional price"
                        />
                      </div>

                      <label className="flex items-center">
                        <input
                          type="radio"
                          name={`default-${customIndex}`}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                          checked={option.isDefault}
                          onChange={() => {
                            // Update all options in this customization to set isDefault false
                            // except for the current one
                            setFormData(prev => ({
                              ...prev,
                              customizations: prev.customizations.map((custom, i) =>
                                i === customIndex ? {
                                  ...custom,
                                  options: custom.options.map((opt, j) => ({
                                    ...opt,
                                    isDefault: j === optionIndex
                                  }))
                                } : custom
                              )
                            }));
                          }}
                        />
                        <span className="ml-2 text-sm text-gray-600">Default</span>
                      </label>

                      {customization.options.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeCustomizationOption(customIndex, optionIndex)}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={() => addCustomizationOption(customIndex)}
                    className="mt-2 inline-flex items-center text-sm text-indigo-600 hover:text-indigo-500"
                  >
                    <PlusIcon className="h-4 w-4 mr-1" />
                    Add Option
                  </button>
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={addCustomization}
              className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="h-4 w-4 mr-2" />
              Add Customization Group
            </button>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Images</h2>
          <ImageUpload
            images={formData.images}
            onImagesChange={(newImages) => setFormData(prev => ({
              ...prev,
              images: newImages
            }))}
            primaryImageId={formData.primaryImageId}
            onPrimaryImageChange={(id) => {
              // Find the new primary image
              const primaryImage = formData.images.find(img => img.id === id);

              setFormData(prev => ({
                ...prev,
                primaryImageId: id,
                primaryImageUrl: primaryImage?.url || null
              }));
            }}
          />
        </div>

        {/* Form Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/dashboard/products')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <>
                <Spinner className="w-4 h-4 mr-2" />
                Saving...
              </>
            ) : (
              'Save Product'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;