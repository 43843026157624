// dashboard/src/components/layouts/SignupLayout.jsx
import React from 'react';

const SignupLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100 relative overflow-hidden">
      {/* Inverted Gradient Triangle Background */}
      <div
        className="absolute bottom-0 left-0 w-full transform skew-y-6 origin-bottom-left"
        style={{
          background: 'linear-gradient(45deg, #6366f1 0%, #a855f7 100%)',
          zIndex: 0,
          marginBottom: '-10%',
          height: 'calc(290px + 10.6278vw)'
        }}
      />

      {/* Logo */}
      <div className="relative z-10 p-8">
        <img
          src="/images/logo.png"
          alt="WhizPay Logo"
          className="h-10"
        />
      </div>

      {/* Content */}
      <div className="relative z-10 mt-[100px] min-h-[calc(100vh-169px)]">
        {children}
      </div>

      {/* Footer */}
      <div className="relative z-10 h-[69px] flex items-center justify-center">
        <a href="/terms" className="text-sm text-gray-600 hover:text-gray-900">
          Terms & Conditions
        </a>
      </div>
    </div>
  );
};

export default SignupLayout;