// dashboard/src/pages/LocationProducts.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Alert, AlertDescription } from '../components/ui/alert';
import DetailTooltip from '../components/common/DetailTooltip';
import Spinner from '../components/common/Spinner';
import { useLocations } from '../contexts/LocationContext';

const LocationProducts = () => {
  const { id: locationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const [locationProducts, setLocationProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [configuring, setConfiguring] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const { refetchLocations } = useLocations();
  const [configData, setConfigData] = useState({
    isEnabled: true,
    hasLimitedQuantity: false,
    remainingQuantity: 0,
    priceOverrides: {},
    customizationConfigs: []
  });

  // Add this new function for handling edit
  const handleEdit = (locationProduct) => {
    setSelectedProduct(locationProduct.product);
    setEditingProduct(locationProduct);

    // Initialize with default values from base product
    setConfigData({
      isEnabled: locationProduct.isEnabled ?? true,
      hasLimitedQuantity: locationProduct.hasLimitedQuantity ?? false,
      remainingQuantity: locationProduct.remainingQuantity || 0,
      priceOverrides: { ...locationProduct.product.basePrices, ...locationProduct.priceOverrides },
      customizationConfigs: locationProduct.product.customizations.map(customization => ({
        id: customization.id,
        name: customization.name,
        optionOverrides: customization.options.map(option => {
          const existingOverride = locationProduct.customizationConfigs
            ?.find(c => c.name === customization.name)
            ?.optionOverrides
            ?.find(o => o.optionValue === option.value);

          return {
            id: option.id,
            optionValue: option.value,
            isAvailable: existingOverride?.isAvailable ?? true,
            priceOverrides: {
              ...option.additionalPrice,
              ...(existingOverride?.priceOverrides || {})
            }
          };
        })
      }))
    });
    setConfiguring(true);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const [productsResponse, locationProductsResponse] = await Promise.all([
        httpsCallable(functions, 'getAllProducts')({}),
        httpsCallable(functions, 'getLocationProducts')({ locationId })
      ]);

      setAllProducts(productsResponse.data);
      setLocationProducts(locationProductsResponse.data);
    } catch (err) {
      setError('Failed to load products');
      console.error('Error fetching data:', err);
    }
    setLoading(false);
  }, [locationId]);

  // Use the extracted fetchData in useEffect
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddProduct = async (product) => {
    setSelectedProduct(product);
    setConfigData({
      isEnabled: true,
      hasLimitedQuantity: false,
      remainingQuantity: 0,
      priceOverrides: { ...product.basePrices },
      customizationConfigs: product.customizations.map(customization => ({
        name: customization.name,
        id: customization.id,
        optionOverrides: customization.options.map(option => ({
          id: option.id,
          optionValue: option.value,
          isAvailable: true,
          priceOverrides: { ...option.additionalPrice }
        }))
      }))
    });
    setConfiguring(true);
  };

  const handleSaveConfig = async () => {
    try {
      const functions = getFunctions();

      // Helper to check if price overrides are different from base prices
      const getPriceOverrides = () => {
        const overrides = {};
        Object.entries(configData.priceOverrides).forEach(([currency, price]) => {
          if (price !== selectedProduct.basePrices[currency]) {
            overrides[currency] = price;
          }
        });
        return Object.keys(overrides).length > 0 ? overrides : undefined;
      };

      // Helper to process customization configs
      const getCustomizationConfigs = () => {
        return configData.customizationConfigs
          .map(config => {
            // First find the original customization
            const originalCustomization = selectedProduct.customizations?.find(c => c.id === config.id);

            // If we can't find the original customization, skip it
            if (!originalCustomization) {
              console.warn(`Could not find original customization for config:`, config);
              return null;
            }

            const optionOverrides = config.optionOverrides
              .filter(override => {
                // Find the original option
                const originalOption = originalCustomization.options?.find(opt => opt.id === override.id);

                // If we can't find the original option, skip it
                if (!originalOption) {
                  console.warn(`Could not find original option for override:`, override);
                  return false;
                }

                // Only include if availability changed or prices are different
                const availabilityChanged = override.isAvailable !== true;
                const pricesChanged = JSON.stringify(override.priceOverrides) !==
                  JSON.stringify(originalOption.additionalPrice);

                return availabilityChanged || pricesChanged;
              })
              .map(override => ({
                id: override.id,
                optionValue: override.optionValue,
                isAvailable: override.isAvailable,
                priceOverrides: override.priceOverrides
              }));

            // Only return customization config if it has option overrides
            return optionOverrides.length > 0 ? {
              id: config.id,
              name: config.name,
              optionOverrides
            } : null;
          })
          .filter(Boolean); // Remove any null results
      };

      const cleanConfig = {
        ...(configData.isEnabled !== true && { isEnabled: configData.isEnabled }),
        ...(configData.hasLimitedQuantity && {
          hasLimitedQuantity: true,
          remainingQuantity: configData.remainingQuantity
        }),
        ...(getPriceOverrides() && { priceOverrides: getPriceOverrides() })
      };

      const customizationConfigs = getCustomizationConfigs();
      if (customizationConfigs.length > 0) {
        cleanConfig.customizationConfigs = customizationConfigs;
      }

      if (editingProduct) {
        const updateLocationProduct = httpsCallable(functions, 'updateLocationProduct');
        await updateLocationProduct({
          locationId,
          productId: selectedProduct.id,
          updates: cleanConfig
        });
      } else {
        const addProductToLocation = httpsCallable(functions, 'addProductToLocation');
        await addProductToLocation({
          locationId,
          productId: selectedProduct.id,
          config: cleanConfig
        });
      }
      refetchLocations();
      setConfiguring(false);
      setSelectedProduct(null);
      setEditingProduct(null);

      await fetchData();
    } catch (err) {
      setError(editingProduct ? 'Failed to update product' : 'Failed to add product');
      console.error('Error:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Spinner className="h-8 w-8 text-indigo-600" />
      </div>
    );
  }

  const handleRemoveProduct = async (productId) => {
    try {
      const functions = getFunctions();
      const removeProductFromLocation = httpsCallable(functions, 'removeProductFromLocation');
      await removeProductFromLocation({ locationId, productId });

      setLocationProducts(prev => prev.filter(p => p.productId !== productId));
    } catch (err) {
      setError('Failed to remove product');
      console.error('Error:', err);
    }
  };

  const availableProducts = allProducts.filter(
    product => !locationProducts.find(lp => lp.productId === product.id)
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        Location Products
      </h1>

      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {configuring ? (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium mb-4">
            {editingProduct ? 'Edit' : 'Configure'} {selectedProduct.name}
          </h2>

          <div className="space-y-6">
            {/* Basic Configuration */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <label className="font-medium">Enabled</label>
                <input
                  type="checkbox"
                  checked={configData.isEnabled}
                  onChange={(e) => setConfigData(prev => ({
                    ...prev,
                    isEnabled: e.target.checked
                  }))}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>

              <div className="flex items-center justify-between">
                <label className="font-medium">Limited Quantity</label>
                <input
                  type="checkbox"
                  checked={configData.hasLimitedQuantity}
                  onChange={(e) => setConfigData(prev => ({
                    ...prev,
                    hasLimitedQuantity: e.target.checked
                  }))}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>

              {configData.hasLimitedQuantity && (
                <div>
                  <label className="block font-medium mb-1">Quantity</label>
                  <input
                    type="number"
                    min="0"
                    value={configData.remainingQuantity}
                    onChange={(e) => setConfigData(prev => ({
                      ...prev,
                      remainingQuantity: parseInt(e.target.value)
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              )}
            </div>

            {/* Base Price Overrides */}
            <div>
              <h3 className="font-medium mb-2">Base Price Overrides</h3>
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(selectedProduct.basePrices).map(([currency, basePrice]) => (
                  <div key={currency}>
                    <label className="block text-sm font-medium text-gray-700">
                      {currency} Price
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">
                          {currency === 'USD' ? '$' : currency}
                        </span>
                      </div>
                      <input
                        type="number"
                        step="0.01"
                        value={(configData.priceOverrides[currency] || basePrice) / 100}
                        onChange={(e) => setConfigData(prev => ({
                          ...prev,
                          priceOverrides: {
                            ...prev.priceOverrides,
                            [currency]: Math.round(parseFloat(e.target.value) * 100)
                          }
                        }))}
                        className="mt-1 block w-full rounded-md border-gray-300 pl-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Customization Overrides */}
            {selectedProduct.customizations?.length > 0 && (
              <div>
                <h3 className="font-medium mb-2">Customization Configuration</h3>
                <div className="space-y-4">
                  {configData.customizationConfigs.map((config, customIndex) => (
                    <div key={customIndex} className="border rounded-md p-4">
                      <h4 className="font-medium mb-2">{config.name}</h4>
                      <div className="space-y-4">
                        {config.optionOverrides.map((override, optionIndex) => (
                          <div key={optionIndex} className="space-y-2">
                            <div className="flex items-center justify-between">
                              <span>{override.optionValue}</span>
                              <input
                                type="checkbox"
                                checked={override.isAvailable}
                                onChange={(e) => {
                                  const newConfigs = [...configData.customizationConfigs];
                                  newConfigs[customIndex].optionOverrides[optionIndex].isAvailable = e.target.checked;
                                  setConfigData(prev => ({
                                    ...prev,
                                    customizationConfigs: newConfigs
                                  }));
                                }}
                                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                            </div>
                            {override.isAvailable && (
                              <div className="pl-4 grid grid-cols-2 gap-4">
                                {Object.entries(selectedProduct.basePrices).map(([currency]) => (
                                  <div key={currency}>
                                    <label className="block text-sm font-medium text-gray-700">
                                      Additional {currency} Price
                                    </label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">
                                          {currency === 'USD' ? '$' : currency}
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        step="0.01"
                                        value={(override.priceOverrides?.[currency] || 0) / 100}
                                        onChange={(e) => {
                                          const newConfigs = [...configData.customizationConfigs];
                                          newConfigs[customIndex].optionOverrides[optionIndex].priceOverrides = {
                                            ...override.priceOverrides,
                                            [currency]: Math.round(parseFloat(e.target.value) * 100)
                                          };
                                          setConfigData(prev => ({
                                            ...prev,
                                            customizationConfigs: newConfigs
                                          }));
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 pl-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setConfiguring(false);
                  setSelectedProduct(null);
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveConfig}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700"
              >
                Save Configuration
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          {/* Assigned Products */}
          <div>
            <h2 className="text-lg font-medium mb-4">Assigned Products</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {locationProducts.map(product => (
                  <li key={product.productId} className="px-4 py-4 flex items-center justify-between">
                    <div>
                      <span className="text-sm font-medium text-gray-900">
                        {product.product.name}
                      </span>
                      <DetailTooltip
                        trigger={
                          <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5">
                            (View Details)
                          </span>
                        }
                        content={
                          <div className="space-y-2">
                            <div>Enabled: {product.isEnabled ? 'Yes' : 'No'}</div>
                            <div>Limited Quantity: {product.hasLimitedQuantity ? `Yes (${product.remainingQuantity})` : 'No'}</div>
                            {Object.entries(product.priceOverrides || {}).map(([currency, price]) => (
                              <div key={currency}>
                                {currency}: ${(price / 100).toFixed(2)}
                              </div>
                            ))}
                            {product.customizationConfigs?.map(config => (
                              <div key={config.name} className="mt-2">
                                <div className="font-medium">{config.name}</div>
                                {config.optionOverrides.map(opt => (
                                  <div key={opt.optionValue} className="pl-2">
                                    {opt.optionValue}: {opt.isAvailable ? 'Available' : 'Unavailable'}
                                    {opt.isAvailable && opt.priceOverrides && Object.entries(opt.priceOverrides).map(([curr, price]) => (
                                      <span key={curr} className="ml-2">
                                        ({curr}: +${(price / 100).toFixed(2)})
                                      </span>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        }
                      />
                    </div>
                    <div>
                      <button
                        onClick={() => handleEdit(product)}
                        className="ml-2 text-sm text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleRemoveProduct(product.productId)}
                        className="ml-2 text-sm text-red-600 hover:text-red-900"
                      >
                        Remove
                      </button>
                    </div>
                  </li>

                ))}
              </ul>
            </div>
          </div>

          {/* Available Products */}
          <div>
            <h2 className="text-lg font-medium mb-4">Available Products</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {availableProducts.map(product => (
                  <li key={product.id} className="px-4 py-4 flex items-center justify-between">
                    <span className="text-sm font-medium text-gray-900">
                      {product.name}
                    </span>
                    <button
                      onClick={() => handleAddProduct(product)}
                      className="ml-2 text-sm text-indigo-600 hover:text-indigo-900"
                    >
                      Add
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationProducts