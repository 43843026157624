// dashboard/src/components/store/CustomizeDialog.jsx
import React, { useState, useEffect } from 'react';
import { X, Minus, Plus } from 'lucide-react';
import { formatCurrency } from '../../utils/Intl';

const CustomizeDialog = ({ product, onAdd, onClose, colorPalette, currency }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const basePrice = product.price;

  // Initialize with everything unselected for single-option groups
  useEffect(() => {
    const defaults = {};
    product.customizations?.forEach(custom => {
      if (custom.options.length === 1) {
        // Single option groups start unselected
        defaults[custom.id] = {
          id: custom.id,
          name: custom.name,
          value: null,
          optionId: null,
          additionalPrice: 0
        };
      } else {
        // Multiple option groups get default selection
        const defaultOption = custom.options.find(opt => opt.isDefault) || custom.options[0];
        defaults[custom.id] = {
          id: custom.id,
          name: custom.name,
          value: defaultOption.value,
          optionId: defaultOption.id,
          additionalPrice: defaultOption.additionalPrice || 0
        };
      }
    });
    setSelectedOptions(defaults);
  }, [product, currency]);

  const totalPrice = (basePrice +
    Object.values(selectedOptions)
      .reduce((sum, opt) => sum + (opt.additionalPrice || 0), 0)
  ) * quantity;

  const handleAddToCart = () => {
    // Filter out unselected options
    const filteredOptions = Object.entries(selectedOptions).reduce((acc, [key, value]) => {
      if (value.value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    onAdd({
      productId: product.id,
      name: product.name,
      imageUrl: product.primaryImageUrl,
      basePrice,
      quantity,
      selectedOptions: filteredOptions,
      totalBasePrice: basePrice + Object.values(selectedOptions)
        .reduce((sum, opt) => sum + (opt.additionalPrice || 0), 0),
      cartId: Date.now().toString()
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 bg-black/50 z-50">
      <div className="relative w-full max-w-lg rounded-lg p-6 max-h-[90vh] overflow-y-auto"
        style={{ backgroundColor: colorPalette.background }}>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-1 rounded-full hover:bg-black/5"
        >
          <X size={20} />
        </button>

        <div className="flex gap-4 mb-6">
          {product.primaryImageUrl && (
            <img
              src={product.primaryImageUrl}
              alt={product.name}
              className="w-24 h-24 object-cover rounded"
            />
          )}
          <div>
            <h2 className="text-xl font-semibold mb-1">{product.name}</h2>
            <p className="text-sm" style={{ color: colorPalette.textLight }}>
              {product.description}
            </p>
          </div>
        </div>

        {product.customizations?.map(custom => (
          <div key={custom.id} className="mb-6">
            {custom.options.length > 1 ? (
              <h3 className="font-medium mb-2">{custom.name}</h3>
            ) : <span />}
            <div className="grid gap-2">
              {custom.options.length === 1 ? (
                // Single option toggle
                <div className="flex items-center justify-between px-4 py-2 rounded-lg border"
                  style={{ borderColor: colorPalette.textLight + '20' }}>
                  <div>
                    <span>{custom.options[0].value}</span>
                    {custom.options[0].additionalPrice > 0 && (
                      <span className="ml-2 text-sm" style={{ color: colorPalette.textLight }}>
                        +{formatCurrency(custom.options[0].additionalPrice, currency)}
                      </span>
                    )}
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={selectedOptions[custom.id]?.value !== null}
                      onChange={(e) => {
                        setSelectedOptions(prev => ({
                          ...prev,
                          [custom.id]: {
                            id: custom.id,
                            name: custom.name,
                            value: e.target.checked ? custom.options[0].value : null,
                            optionId: e.target.checked ? custom.options[0].id : null,
                            additionalPrice: e.target.checked ?
                              (custom.options[0].additionalPrice || 0) : 0
                          }
                        }));
                      }}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600" />
                  </label>
                </div>
              ) : (
                // Multiple options buttons
                custom.options.map(option => {
                  const isSelected = selectedOptions[custom.id]?.optionId === option.id;
                  const additionalPrice = option.additionalPrice || 0;

                  return (
                    <button
                      key={option.id}
                      className={`text-left px-4 py-2 rounded-lg border transition-colors ${isSelected ? 'border-2' : 'border'}`}
                      style={{
                        borderColor: isSelected ? colorPalette.primary : colorPalette.textLight + '20',
                        backgroundColor: isSelected ? colorPalette.primary + '10' : 'transparent'
                      }}
                      onClick={() => setSelectedOptions(prev => ({
                        ...prev,
                        [custom.id]: {
                          id: custom.id,
                          name: custom.name,
                          value: option.value,
                          optionId: option.id,
                          additionalPrice
                        }
                      }))}
                    >
                      <div className="flex justify-between items-center">
                        <span>{option.value}</span>
                        {additionalPrice > 0 && (
                          <span className="text-sm" style={{ color: colorPalette.textLight }}>
                            +{formatCurrency(additionalPrice, currency)}
                          </span>
                        )}
                      </div>
                    </button>
                  );
                })
              )}
            </div>
          </div>
        ))}

        <div className="flex items-center gap-4 mb-6">
          <span className="font-medium">Quantity:</span>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setQuantity(q => Math.max(1, q - 1))}
              className="p-1 rounded-full hover:bg-black/5"
              disabled={quantity <= 1}
            >
              <Minus size={16} />
            </button>
            <span className="w-8 text-center">{quantity}</span>
            <button
              onClick={() => setQuantity(q => q + 1)}
              className="p-1 rounded-full hover:bg-black/5"
            >
              <Plus size={16} />
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between mb-6">
          <span className="font-medium">Total:</span>
          <span className="text-xl font-bold" style={{ color: colorPalette.accent }}>
            {formatCurrency(totalPrice, currency)}
          </span>
        </div>

        <button
          onClick={handleAddToCart}
          className="w-full py-3 rounded-lg text-white font-medium"
          style={{ backgroundColor: colorPalette.primary }}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default CustomizeDialog;