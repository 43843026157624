// dashboard/src/pages/SuccessPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CheckCircle } from 'lucide-react';
import useCart from '../hooks/useCart';
import { formatCurrency } from '../utils/Intl';
import { Alert, AlertDescription } from '../components/ui/alert';
import Spinner from '../components/common/Spinner';

const OrderSuccessPage = () => {
  const { storeUrl } = useParams();
  const navigate = useNavigate();
  const { clearCart } = useCart(storeUrl);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);
  const [store, setStore] = useState(null);

  useEffect(() => {
    const fetchOrderAndStore = async () => {
      // Get orderId from localStorage
      const orderId = localStorage.getItem(`${storeUrl}_pending_order`);

      if (!orderId) {
        setError('No order found');
        setLoading(false);
        return;
      }

      try {
        const functions = getFunctions();

        // Fetch store details
        const getStore = httpsCallable(functions, 'getLocationByUrl');
        const storeResponse = await getStore({ storeUrl });
        setStore(storeResponse.data.location);

        // Fetch order details using new focused endpoint
        const getOrderForSuccess = httpsCallable(functions, 'getOrderForSuccess');
        const orderResponse = await getOrderForSuccess({ orderId });
        setOrder(orderResponse.data);

        // Clear the pending order from localStorage
        localStorage.removeItem(`${storeUrl}_pending_order`);

        // Clear the cart after successful order fetch
        clearCart();
      } catch (err) {
        console.error('Error fetching order:', err);
        setError('Failed to load order details');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderAndStore();
  }, [storeUrl, clearCart, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Spinner className="h-8 w-8" style={{ color: store?.colorPalette?.primary || '#4F46E5' }} />
      </div>
    );
  }

  if (error || !order || !store) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <Alert variant="destructive">
          <AlertDescription>
            {error || 'Could not load order details'}
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  const { colorPalette } = store;

  return (
    <div style={{ backgroundColor: colorPalette.background, color: colorPalette.text }}>
      <div className="max-w-2xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="flex justify-center mb-8">
          <CheckCircle
            size={48}
            className="text-green-500"
          />
        </div>

        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Thank You!</h1>
          <p className="text-lg" style={{ color: colorPalette.textLight }}>
            Your order has been confirmed
          </p>
          <p className="text-sm mt-2" style={{ color: colorPalette.textLight }}>
            Order #{order.id}
          </p>
        </div>

        <div className="border rounded-lg overflow-hidden" style={{ borderColor: `${colorPalette.textLight}20` }}>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium">Order Summary</h3>
          </div>
          <div className="border-t" style={{ borderColor: `${colorPalette.textLight}20` }}>
            <dl>
              {/* Order Items */}
              <div className="px-4 py-5 sm:px-6">
                {order.items.map((item, index) => (
                  <div key={`${item.productId}-${index}`} className="flex justify-between py-2">
                    <div className="flex-1">
                      <div className="flex justify-between">
                        <span>
                          <span className="font-medium">{item.quantity}x </span>
                          {item.productSnapshot.name}
                        </span>
                        <span style={{ color: colorPalette.accent }}>
                          {formatCurrency(item.totalPrice, order.currency)}
                        </span>
                      </div>
                      {item.customizations && item.customizations.length > 0 && (
                        <div className="mt-1 text-sm" style={{ color: colorPalette.textLight }}>
                          {item.customizations.map((customization, custIndex) => (
                            <span key={`${customization.name}-${custIndex}`}>
                              {custIndex > 0 ? ' • ' : ''}
                              {customization.selectedOption}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {/* Totals */}
              <div className="border-t px-4 py-5 sm:px-6" style={{ borderColor: `${colorPalette.textLight}20` }}>
                <div className="flex justify-between py-2">
                  <div style={{ color: colorPalette.textLight }}>Subtotal</div>
                  <div>{formatCurrency(order.subtotal, order.currency)}</div>
                </div>
                {order.adjustmentsTotal > 0 && (
                  <div className="flex justify-between py-2">
                    <div style={{ color: colorPalette.textLight }}>Adjustments</div>
                    <div>{formatCurrency(order.adjustmentsTotal, order.currency)}</div>
                  </div>
                )}
                <div className="flex justify-between py-2 font-bold">
                  <div>Total</div>
                  <div style={{ color: colorPalette.accent }}>
                    {formatCurrency(order.total, order.currency)}
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-8 text-center">
          <a
            href={`/store/${storeUrl}`}
            className="inline-block px-6 py-3 rounded-lg text-white font-medium"
            style={{ backgroundColor: colorPalette.primary }}
          >
            Continue Shopping
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessPage;