// dashboard/src/components/menus/UserActionMenu.jsx
import React, { Fragment, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../hooks/useAuth';
import { createPortal } from 'react-dom';

const UserActionMenu = ({ user, onActivate, onDeactivate, onUpdateRoles }) => {
  const { user: currentUser } = useAuth();
  const isCurrentUser = currentUser?.uid === user.id;
  const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
  const buttonRef = useRef(null);

  const updateMenuPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const menuHeight = 100; // Approximate height of menu item

      let top;
      if (spaceBelow < menuHeight) {
        // If not enough space below, show above the button
        top = rect.top - menuHeight;
      } else {
        // Show below the button
        top = rect.bottom;
      }
      setMenuPosition({
        // Add window.scrollY to account for scroll position
        top: top,
        right: window.innerWidth - rect.right,
      });
    }
  };

  const handleRoleToggle = (role) => {
    const newRoles = user.roles.includes(role)
      ? user.roles.filter(r => r !== role)
      : [...user.roles, role];
    onUpdateRoles(newRoles);
  };

  const handleDeactivate = () => {
    if (window.confirm(`Are you sure you want to deactivate ${user.email}?`)) {
      onDeactivate();
    }
  };

  const availableRoles = ['admin', 'staff', 'user'];

  if (isCurrentUser) {
    return (
      <button
        disabled
        title="Cannot modify your own user account"
        className="rounded-full p-2 cursor-not-allowed bg-gray-100 text-gray-400"
      >
        <EllipsisHorizontalIcon className="h-5 w-5" />
      </button>
    );
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        ref={buttonRef}
        onClick={updateMenuPosition}
        className="rounded-full p-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" />
      </Menu.Button>

      {createPortal(
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="fixed z-50 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            style={{
              top: `${menuPosition.top}px`,
              right: `${menuPosition.right}px`
            }}
          >
            <div className="py-1">
              {/* Active/Deactivate Option */}
              {!isCurrentUser && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={user.isActive ? handleDeactivate : onActivate}
                      className={`
                      ${active ? 'bg-gray-100' : ''}
                      ${user.isActive ? 'text-red-700' : 'text-green-700'}
                      block w-full px-4 py-2 text-left text-sm
                    `}
                    >
                      {user.isActive ? 'Deactivate User' : 'Activate User'}
                    </button>
                  )}
                </Menu.Item>
              )}

              {/* Role Management Section */}
              {!isCurrentUser && (
                <div className="border-t border-gray-100">
                  <div className="px-4 py-2 text-xs font-semibold text-gray-500">
                    User Roles
                  </div>
                  {availableRoles.map((role) => (
                    <Menu.Item key={role}>
                      {({ active }) => (
                        <div
                          className={`
                          ${active ? 'bg-gray-100' : ''}
                          px-4 py-2 text-sm text-gray-700 flex items-center
                        `}
                        >
                          <input
                            type="checkbox"
                            checked={user.roles.includes(role)}
                            onChange={() => handleRoleToggle(role)}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-3"
                          />
                          {role.charAt(0).toUpperCase() + role.slice(1)}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              )}
            </div>
          </Menu.Items>
        </Transition>,
        document.body
      )}
    </Menu>
  );
};

export default UserActionMenu;