// dashboard/src/pages/Products.jsx
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Spinner from '../components/common/Spinner'
import DetailTooltip from '../components/common/DetailTooltip'
import FilterButton from '../components/common/FilterButton'
import ProductActionMenu from '../components/menus/ProductActionMenu'
import { useProducts } from '../contexts/ProductContext';
import { Table, TableHeader, SortableTableHeaderCell, TableHeaderCell, TableBody, TableRow, TableCell } from '../components/ui/Table';
import { formatCurrency } from "../utils/Intl"

const PriceDetails = ({ prices }) => {
  if (!prices || Object.keys(prices).length === 0) return "No prices set";
  return (
    <div className="space-y-1">
      {Object.entries(prices).map(([currency, price]) => (
        <div key={currency}>
          {currency}: {price.toFixed(2)}
        </div>
      ))}
    </div>
  );
};

const CustomizationDetails = ({ customizations }) => {
  if (!customizations || customizations.length === 0) return "No customizations";
  return (
    <div className="space-y-2">
      {customizations.map(customization => (
        <div key={customization.name}>
          <div className="font-medium">{customization.name}</div>
          <div className="ml-2">
            {customization.options.map(option => (
              <div key={option.value}>
                {option.value}
                {option.additionalPrice && Object.keys(option.additionalPrice).length > 0 &&
                  ` (+${Object.values(option.additionalPrice)[0]})`}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductsPage = () => {
  const navigate = useNavigate();
  const { products, productsLoading } = useProducts();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const filterCounts = useMemo(() => ({
    all: products.length,
    active: products.filter(p => p.isActive).length,
    hasCategories: products.filter(p => p.categories?.length > 0).length,
    needsImage: products.filter(p => !p.images || p.images.length === 0).length
  }), [products]);

  const filteredProducts = useMemo(() => {
    return products
      .filter(product => {
        // Search filter
        if (searchTerm) {
          const searchLower = searchTerm.toLowerCase();
          const matchesName = product.name.toLowerCase().includes(searchLower);
          const matchesCategories = product.categories?.some(cat =>
            cat.toLowerCase().includes(searchLower)
          );
          const matchesCustomizations = product.customizations?.some(custom =>
            custom.name.toLowerCase().includes(searchLower) ||
            custom.options.some(opt => opt.value.toLowerCase().includes(searchLower))
          );

          if (!matchesName && !matchesCategories && !matchesCustomizations) {
            return false;
          }
        }

        // Status filters
        switch (activeFilter) {
          case 'active':
            return product.isActive;
          case 'hasCategories':
            return product.categories?.length > 0;
          case 'needsImage':
            return !product.images || product.images.length === 0;
          default:
            return true;
        }
      })
      .sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];
        const direction = sortDirection === 'asc' ? 1 : -1;
        return aValue < bValue ? -direction : direction;
      });
  }, [products, searchTerm, activeFilter, sortField, sortDirection]);

  const SortHeader = ({ field, children }) => (
    <SortableTableHeaderCell onClick={() => handleSort(field)}
      isSorted={sortField === field} sortDirection={sortDirection}>
      {children}
    </SortableTableHeaderCell>
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Search Bar */}
      <div className="relative mb-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Products</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => navigate('/dashboard/products/new')}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Create Product
          </button>
        </div>
      </div>

      <div className="mb-6">
        <FilterButton
          label="All"
          count={filterCounts.all}
          active={activeFilter === 'all'}
          onClick={() => setActiveFilter('all')}
        />
        <FilterButton
          label="Active"
          count={filterCounts.active}
          active={activeFilter === 'active'}
          onClick={() => setActiveFilter('active')}
        />
        <FilterButton
          label="Has Categories"
          count={filterCounts.hasCategories}
          active={activeFilter === 'hasCategories'}
          onClick={() => setActiveFilter('hasCategories')}
        />
        <FilterButton
          label="Needs Image"
          count={filterCounts.needsImage}
          active={activeFilter === 'needsImage'}
          onClick={() => setActiveFilter('needsImage')}
        />
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {productsLoading ? (
              <div className="flex justify-center items-center min-h-[400px]">
                <Spinner className="h-8 w-8 text-indigo-600" />
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell className="px-6 py-3">Image</TableHeaderCell>
                    <SortHeader field="name">Name</SortHeader>
                    <TableHeaderCell className="px-6 py-3">Prices</TableHeaderCell>
                    <TableHeaderCell className="px-6 py-3">Categories</TableHeaderCell>
                    <TableHeaderCell className="px-6 py-3">Customizations</TableHeaderCell>
                    <SortHeader field="id">ID</SortHeader>
                    <TableHeaderCell className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredProducts.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        {product.primaryImageUrl ? (
                          <img
                            src={product.primaryImageUrl}
                            alt={product.name}
                            className="w-10 h-10 object-contain rounded"
                          />
                        ) : (
                          <div className="w-16 h-16 bg-gray-100 rounded" />
                        )}
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        <DetailTooltip
                          trigger={
                            product.basePrices && product.basePrices["USD"] ? formatCurrency(product.basePrices["USD"]) : ""}
                          content={<PriceDetails prices={product.basePrices} />}
                        />
                      </TableCell>
                      <TableCell>{product.categories?.length ?
                        product.categories?.length === 1 ? product.categories[0] :
                          <DetailTooltip
                            trigger={`${product.categories.length} categories`}
                            content={product.categories.join(", ")} />
                        : <div className="text-gray-400">None</div>}
                      </TableCell>
                      <TableCell>
                        {product.customizations?.length ?
                          product.customizations?.length === 1 ? product.categories[0] :
                            <DetailTooltip
                              trigger={`${product.customizations.length} options`}
                              content={<CustomizationDetails customizations={product.customizations} />} />
                          : <div className="text-gray-400">None</div>}
                      </TableCell>
                      <TableCell>{product.id}</TableCell>
                      <TableCell><ProductActionMenu product={product} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>

      {/* Show total count */}
      <div className="mt-6">
        <div className="text-sm text-gray-700">
          {filteredProducts.length !== products.length
            ? `Showing ${filteredProducts.length} products of ${products.length}`
            : `${products.length} products`}
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;