// dashboard/src/pages/Customers.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Spinner from '../components/common/Spinner';
import FilterButton from '../components/common/FilterButton';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { formatTimestamp } from '../utils/utils'
import { formatCurrency } from '../utils/Intl';
import CustomerOrdersModal from '../components/customers/CustomerOrdersModal';
import { Table, TableHeader, TableHeaderCell, SortableTableHeaderCell, TableBody, TableRow, TableCell } from '../components/ui/Table';

const CustomersPage = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    hasMore: true,
    lastCustomerId: null
  });

  const filterCounts = useMemo(() => ({
    all: customers.length,
    hasOrders: customers.filter(c => c.orderCount > 0).length,
    noOrders: customers.filter(c => c.orderCount === 0).length,
    highValue: customers.filter(c => c.totalSpent > 10000).length // $100 threshold
  }), [customers]);

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const listCustomers = httpsCallable(functions, 'listCustomers');
      const response = await listCustomers({
        limit: 20,
        sortField,
        sortDirection,
        lastCustomerId: pagination.lastCustomerId,
        searchTerm: searchTerm || undefined
      });

      setCustomers(response.data.customers);
      setPagination(prev => ({
        ...prev,
        hasMore: response.data.hasMore
      }));
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
    setLoading(false);
  }, [sortField, sortDirection, pagination.lastCustomerId, searchTerm]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
    setPagination(prev => ({
      ...prev,
      lastCustomerId: null,
      currentPage: 1
    }));
  };

  const handleNextPage = () => {
    if (customers.length > 0 && pagination.hasMore) {
      setPagination(prev => ({
        ...prev,
        currentPage: prev.currentPage + 1,
        lastCustomerId: customers[customers.length - 1].id
      }));
    }
  };

  const handlePrevPage = () => {
    if (pagination.currentPage > 1) {
      setPagination(prev => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        lastCustomerId: null // We'll need to refetch from the beginning
      }));
    }
  };

  const SortHeader = ({ field, children }) => (
    <SortableTableHeaderCell onClick={() => handleSort(field)}
      isSorted={sortField === field} sortDirection={sortDirection}>
      {children}
    </SortableTableHeaderCell>
  );

  const filteredCustomers = useMemo(() => {
    return customers.filter(customer => {
      switch (activeFilter) {
        case 'hasOrders':
          return customer.orderCount > 0;
        case 'noOrders':
          return customer.orderCount === 0;
        case 'highValue':
          return customer.totalSpent > 10000; // $100 threshold
        default:
          return true;
      }
    });
  }, [customers, activeFilter]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Search Bar */}
      <div className="relative mb-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search customers by name or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Customers</h1>

      {/* Filter Buttons */}
      <div className="mb-6">
        <FilterButton
          label="All"
          count={filterCounts.all}
          active={activeFilter === 'all'}
          onClick={() => setActiveFilter('all')}
        />
        <FilterButton
          label="Has Orders"
          count={filterCounts.hasOrders}
          active={activeFilter === 'hasOrders'}
          onClick={() => setActiveFilter('hasOrders')}
        />
        <FilterButton
          label="No Orders"
          count={filterCounts.noOrders}
          active={activeFilter === 'noOrders'}
          onClick={() => setActiveFilter('noOrders')}
        />
        <FilterButton
          label="High Value"
          count={filterCounts.highValue}
          active={activeFilter === 'highValue'}
          onClick={() => setActiveFilter('highValue')}
        />
      </div>

      {/* Customers Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <div className="flex justify-center items-center min-h-[400px]">
                <Spinner className="h-8 w-8 text-indigo-600" />
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <SortHeader field="name">Name</SortHeader>
                    <SortHeader field="email">Email</SortHeader>
                    <SortHeader field="phone">Phone</SortHeader>
                    <SortHeader field="orderCount">Orders</SortHeader>
                    <SortHeader field="totalSpent">Total Spent</SortHeader>
                    <TableHeaderCell className="px-6 py-3">Recent Orders</TableHeaderCell>
                    <SortHeader field="createdAt">First Order</SortHeader>
                    <SortHeader field="lastOrderAt">Last Order</SortHeader>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredCustomers.map((customer) => (
                    <TableRow key={customer.id}>
                      <TableCell>{customer.name || 'Guest Customer'}</TableCell>
                      <TableCell>{customer.primaryEmail || '-'}</TableCell>
                      <TableCell>{customer.primaryPhone || '-'}</TableCell>
                      <TableCell>{customer.orderCount}</TableCell>
                      <TableCell>{formatCurrency(customer.totalSpent, 'USD')}</TableCell>
                      <TableCell>
                        <button
                          onClick={() => setSelectedCustomer(customer)}
                          className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800 hover:bg-blue-200"
                        >View Orders</button>
                      </TableCell>
                      <TableCell>{formatTimestamp(customer.firstOrderAt)}</TableCell>
                      <TableCell>{formatTimestamp(customer.lastOrderAt)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>
      <CustomerOrdersModal
        customer={selectedCustomer}
        isOpen={!!selectedCustomer}
        onClose={() => setSelectedCustomer(null)}
      />

      {/* Pagination */}
      <div className="mt-6 flex items-center justify-between">
        <div className="text-sm text-gray-700">
          Showing {customers.length} customers • Page {pagination.currentPage}
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handlePrevPage}
            disabled={pagination.currentPage === 1 || loading}
            className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={!pagination.hasMore || loading}
            className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;