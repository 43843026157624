// dashboard/src/pages/LocationForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Alert, AlertDescription } from '../components/ui/alert';
import { currencies } from '../utils/Intl';
import { useLocations } from "../contexts/LocationContext"
import Spinner from '../components/common/Spinner';

const checkoutTypes = [
  { value: 'hosted_checkout', label: 'Hosted Checkout' },
  { value: 'embedded_checkout', label: 'Embedded Checkout' },
  { value: 'custom', label: 'Custom' }
];

const defaultColorPalette = {
  primary: "#2563eb",
  secondary: "#4f46e5",
  accent: "#ef4444",
  background: "#ffffff",
  text: "#111827",
  textLight: "#6b7280"
};

const LocationForm = () => {
  const { id } = useParams();
  const { getLocation, refetchLocations } = useLocations();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    description: '',
    publicName: '',
    storeUrl: '',
    secretKey: '',
    readers: [''],
    currency: 'USD',
    checkoutType: 'hosted_checkout',
    colorPalette: defaultColorPalette
  });

  useEffect(() => {
    const fetchLocation = async () => {
      if (!id) return;

      setLoading(true);
      try {
        const location = await getLocation(id);

        setFormData({
          description: location.description || '',
          publicName: location.publicName || '',
          storeUrl: location.storeUrl || '',
          secretKey: location.secretKey || '',
          readers: location.readers?.length ? location.readers : [''],
          currency: location.currency || 'USD',
          checkoutType: location.checkoutType || 'hosted_checkout',
          colorPalette: location.colorPalette || defaultColorPalette
        });
      } catch (err) {
        setError('Failed to load location details');
        console.error('Error fetching location:', err);
      }
      setLoading(false);
    };

    fetchLocation();
  }, [id, getLocation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // Filter out empty reader IDs
    const cleanedData = {
      ...formData,
      readers: formData.readers.filter(r => r.trim())
    };

    try {
      const functions = getFunctions();
      const functionName = id ? 'updateLocation' : 'createLocation';
      const cloudFunction = httpsCallable(functions, functionName);

      await cloudFunction({
        locationId: id,
        ...cleanedData
      });

      refetchLocations();
      navigate('/dashboard/locations');
    } catch (err) {
      setError(err.message || 'An error occurred while saving the location');
      console.error('Error saving location:', err);
    }
    setLoading(false);
  };

  const addReader = () => {
    setFormData(prev => ({
      ...prev,
      readers: [...prev.readers, '']
    }));
  };

  const removeReader = (index) => {
    setFormData(prev => ({
      ...prev,
      readers: prev.readers.filter((_, i) => i !== index)
    }));
  };

  const updateReader = (index, value) => {
    setFormData(prev => ({
      ...prev,
      readers: prev.readers.map((reader, i) =>
        i === index ? value : reader
      )
    }));
  };

  const updateColorPalette = (key, value) => {
    setFormData(prev => ({
      ...prev,
      colorPalette: {
        ...prev.colorPalette,
        [key]: value
      }
    }));
  };

  if (loading && id) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Spinner className="h-8 w-8 text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">
        {id ? 'Edit Location' : 'Create New Location'}
      </h1>

      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white shadow rounded-lg p-6 space-y-4">
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description *
            </label>
            <input
              type="text"
              id="description"
              required
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            />
          </div>

          <div>
            <label htmlFor="publicName" className="block text-sm font-medium text-gray-700">
              Public Name *
            </label>
            <input
              type="text"
              id="publicName"
              required
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={formData.publicName}
              onChange={(e) => setFormData(prev => ({ ...prev, publicName: e.target.value }))}
            />
          </div>

          <div>
            <label htmlFor="storeUrl" className="block text-sm font-medium text-gray-700">
              Store URL
            </label>
            <input
              type="text"
              id="storeUrl"
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={formData.storeUrl}
              onChange={(e) => setFormData(prev => ({ ...prev, storeUrl: e.target.value }))}
            />
          </div>

          <div>
            <label htmlFor="secretKey" className="block text-sm font-medium text-gray-700">
              Secret Key *
            </label>
            <input
              type="text"
              id="secretKey"
              required
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={formData.secretKey}
              onChange={(e) => setFormData(prev => ({ ...prev, secretKey: e.target.value }))}
            />
          </div>

          <div>
            <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
              Currency *
            </label>
            <select
              id="currency"
              required
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={formData.currency}
              onChange={(e) => setFormData(prev => ({ ...prev, currency: e.target.value }))}
            >
              {currencies.map(currency => (
                <option key={currency.code} value={currency.code}>
                  {currency.label} ({currency.code})
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="checkoutType" className="block text-sm font-medium text-gray-700">
              Checkout Type *
            </label>
            <select
              id="checkoutType"
              required
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={formData.checkoutType}
              onChange={(e) => setFormData(prev => ({ ...prev, checkoutType: e.target.value }))}
            >
              {checkoutTypes.map(type => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Color Palette</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {Object.entries(formData.colorPalette).map(([key, value]) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 capitalize">
                  {key.replace(/([A-Z])/g, ' $1').trim()}
                </label>
                <div className="mt-1 flex items-center gap-2">
                  <input
                    type="color"
                    value={value}
                    onChange={(e) => updateColorPalette(key, e.target.value)}
                    className="h-8 w-8 rounded-md border border-gray-300"
                  />
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => updateColorPalette(key, e.target.value)}
                    className="flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm"
                    pattern="^#[0-9A-Fa-f]{6}$"
                    placeholder="#000000"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Reader IDs</h2>
          <div className="space-y-2">
            {formData.readers.map((reader, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  className="flex-1 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={reader}
                  onChange={(e) => updateReader(index, e.target.value)}
                  placeholder="Enter reader ID"
                />
                {formData.readers.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeReader(index)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <XCircleIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addReader}
              className="mt-2 text-sm text-indigo-600 hover:text-indigo-500"
            >
              Add Reader
            </button>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/dashboard/locations')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <>
                <Spinner className="w-4 h-4 mr-2" />
                Saving...
              </>
            ) : (
              'Save Location'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LocationForm;